import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from "@material-ui/core/Button";
import { orangeStrong } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    dialogPaper: {
        backgroundColor: "#F2F2F2"
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));


export default function BottomRegister():JSX.Element {


    /*
     TODO:購入処理
     DB連携等
     */
    useEffect(() => {
    }, [])
    const classes = useStyles();
    return (
        <React.Fragment>

            <AppBar position="fixed"  className={classes.appBar}>
                <Button
                    type={"submit"}
                    style={{
                        width: "58%",
                        margin: "13px auto 10px",
                        backgroundColor: orangeStrong,
                        color: "white"
                    }}>
                    登録する
                </Button>
            </AppBar>
        </React.Fragment>
    );
}
