import React, { FC } from 'react'
import { addressFormType } from "../../utils/types/address";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import shortid from "shortid";
import Box from "@material-ui/core/Box";
import { lightGray } from "../../utils/utils";


type AddressFormInputs = {
    data: addressFormType[],
    register: any
}
const AddressFormInputs: FC<AddressFormInputs> =
    ({
        data,
        register
    }) => {
        return (
            <>
                {data.map((d1) => (
                    <>
                        <Grid item xs={4}
                            style={{
                                margin: "15px 0px",
                            }}
                        >
                            <Typography style={{
                                fontWeight: 800
                            }}>
                                {d1.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputBase
                                key={shortid.generate()}
                                placeholder={d1.placeholder}
                                autoComplete={d1.autoComplete}
                                name={d1.name}
                                inputProps={{ 'aria-label': 'naked', ref: register({ required: true }) }}
                            />
                        </Grid>
                        <Box style={{
                            backgroundColor: lightGray,
                            height: "1px",
                            width: "100%"

                        }}
                        />
                    </>
                ))}

            </>
        )
    }
export default AddressFormInputs