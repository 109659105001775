import React, { FC } from 'react';
import MyHeader from '../molecules/MyHeader';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { switchTxtByEnvUserId } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        padding: '63px 25px',
    },
    title: {
        fontSize: '23px',
        fontWeight: 'bold',
        marginBottom: '13px',
    },
    text: {
        fontWeight: 'bold',
        marginBottom: '41px',
    },
    img: {
        width: '130px',
        height: '113px',
        marginBottom: '67px',
    },
    btn: {
        fontSize: '17px',
        fontWeight: 'bold',
    }
}));

type OrderCompleteTemplateProps = {
    onCloseClick:(()=>void);
    imageUrl:string;
    title?:string;
}


const OrderCompleteTemplate: FC<OrderCompleteTemplateProps> =
        ({
             onCloseClick,
             title="",
             imageUrl

        }) => {
    const classes = useStyles();
    const deliveryTxtPrefix = switchTxtByEnvUserId()

    return (
            <>
                <MyHeader title={`${deliveryTxtPrefix}手続き`} />
                <div className={classes.container}>
                    <Typography color='primary' className={classes.title}>{title}</Typography>
                    <img
                            className={classes.img}
                            src={imageUrl}
                            alt={imageUrl}
                    />
                    <Button fullWidth variant="outlined" color='primary' className={classes.btn}
                            onClick={onCloseClick}>閉じる</Button>
                </div>
            </>
    );
}
export default OrderCompleteTemplate;