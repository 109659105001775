import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

interface ButtonAppBarProps {
    title:string;
}

export default function ButtonAppBar({title}:ButtonAppBarProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{
                backgroundColor: '#FFFFFF',fontWeight:'bold'
            }}>
                <Toolbar>

                    <Typography align={"center"} variant="h5" style={{
                        color: "black",
                        margin: "0 auto"
                    }}>
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}
