import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

interface LoadingButtonProps {
    setClose:(()=>void);
    isSuccess?:boolean;
    isLoading?:boolean;
    handleClick?:(()=>void)
}

const LoadingButton =
    ({
         setClose,
         isSuccess=false,
         isLoading=false,
         handleClick
     }:LoadingButtonProps):JSX.Element => {
        const classes = useStyles();
        const buttonClassname = clsx({
            [classes.buttonSuccess]: isSuccess,
        });

        

        React.useEffect(() => {
        }, []);


        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            backgroundColor: "#EE5508"
                        }}
                        className={buttonClassname}
                        disabled={isLoading}
                        onClick={handleClick}
                    >
                        購入する

                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                </div>
                <Button autoFocus onClick={setClose} disabled={isLoading} color="primary">
                    キャンセル
                    
                </Button>
            </div>
        );
    }
export default LoadingButton;