import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigationEazii from "../molecules/BottomNavigationEazii";
import MyHeader from "../molecules/MyHeader";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { purchaseDataState } from "../../atoms/PurchaseData";
import { selectedAddressInfo } from "../../atoms/addressData"
import { selectedCardInfo } from "../../atoms/cardInfo";
import { toYen } from "../../utils/utils";
import { useForm } from "react-hook-form"
import { createCharge } from "../../utils/httpRequest";
import { payJpCustomerObj } from "../../atoms/payjp";
import shortid from "shortid";
import { paymentMethodState } from '../../atoms/paymentMethodData';
import BoxTypographyBottom from "../atoms/BoxTypographyBottom";
import InputAmount from "../atoms/InputAmount";
import DeleteButton from "../atoms/Button/DeleteButton";
import DeleteAlert from "../molecules/Alert/DeleteAlert";
import DeliveryOptions from "../organisms/DeliveryOptions";
import PaymentMethodOptions from "../organisms/PaymentMethodOptions";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: "120px",/*←footerの高さ*/
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));


// [START Purchase]
export default function Purchase() {

    const history = useHistory();
    const [purchaseData, setPurchaseData] = useRecoilState(purchaseDataState);
    const selectedAddress = useRecoilValue(selectedAddressInfo)
    const selectedCard = useRecoilValue(selectedCardInfo)
    // LoadingButonで使用する
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [isPurchaseSuccess, setPurchaseSuccess] = useState(false);
    const customerInfo = useRecoilValue(payJpCustomerObj);
    const { register, setValue } = useForm();
    const [paymentMethod, setPaymentMethod] = useRecoilState(paymentMethodState);
    const [deleteAlertId, setDeleteAlertId] = useState('');

    const handleChange = (event) => {
        setValue("materialInput", event.target.value);
        setPurchaseData({ ...purchaseData, orderMethod: event.target.value })
    }

    const handlePurchaseButtonClick = () => {
        setPurchaseLoading(true)
        setPurchaseSuccess(false)
        createCharge(purchaseData.sumPrice, customerInfo.customer, "", true)
            .then(async result => {
                if (result.captured) {
                    setPurchaseSuccess(true)
                }
            })
            .catch((chargeCreateErr) => {
                // TODO charge作成時のエラー
            }).finally(() => {
            setPurchaseLoading(false);
            setPurchaseData({
                items: {},
                totalSalesAmount:0,
                sumPrice: 0,
                orderMethod:"",
                tableId:0
            })
            history.push('/ordercomplete');
        })

    }
    /**
     *
     */
    const handlePaymegthodChange = (event) => {
        setPaymentMethod(event.target.value)
    }
    const handlePaymentMethodClick = () => {
        history.push("/paymentmethod")
    }
    useEffect(() => {

    }, [deleteAlertId])
    /**
     * 削除ボタン押下時
     */
    const handleDeleteClick = (item) => {
        setDeleteAlertId(item)
    }
    /**
     * 削除AlertのYes押下時
     */
    const handleDeleteYesClick = () => {
        let _purchaseData = JSON.parse(JSON.stringify(purchaseData));
        _purchaseData.sumPrice -= +(_purchaseData["items"][deleteAlertId].taxIncludePrice) * +(_purchaseData['items'][deleteAlertId].salesAmount)
        _purchaseData.totalSalesAmount -= +(_purchaseData["items"][deleteAlertId].salesAmount)
        _purchaseData["items"][deleteAlertId].salesAmount = 0
        setPurchaseData(_purchaseData)
        if (!_purchaseData.totalSalesAmount) {
            history.push('/')
        }
        handleDeleteAlertClose()
    }

    /**
     * 削除AlertのClose処理
     */
    const handleDeleteAlertClose = () => {
        setDeleteAlertId('')
    }
// [START handleAddressChangeClick]
    const handleAddressChangeClick = () => {
        history.push("/address")
    }
    // [END handleAddressChangeClick]
    useEffect(() => {
        if (Object.keys(purchaseData.items).length <= 0 || !purchaseData.totalSalesAmount) {
            history.push("/")
        } else {
            let canPurchase = false
            for (let id in purchaseData.items) {

                canPurchase = purchaseData["items"][id].hasOwnProperty("salesAmount")
            }
            if (!canPurchase) {
                history.push("/")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseData])

    useEffect(() => {
        register("materialInput");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const classes = useStyles();

    return (
        <>
            <MyHeader title={"ご注文手続き"} />
            <div className={classes.root}>


                {Object.keys(purchaseData.items).map((item) => (
                    <React.Fragment key={shortid.generate()}>
                        {purchaseData.items[item].salesAmount > 0 && (
                            <>
                                <Grid container spacing={1}
                                      style={{
                                          margin: "10px 10px 0 0"
                                      }}
                                >
                                    <Grid item xs={3}>
                                        <img className={classes.img} alt="complex"
                                             src={purchaseData.items[item].imageUrls[0]} />
                                    </Grid>
                                    <Grid item xs={4}
                                          style={{
                                              margin: "auto 0"
                                          }}
                                    >
                                        <Typography align={"left"} color='primary'
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: "bold",
                                                        marginBottom: "10px",
                                                    }}
                                        >
                                            {purchaseData.items[item].itemName}
                                        </Typography>
                                        <Typography align={"left"} style={{ fontSize: 13 }} color='primary'>
                                            {purchaseData.items[item].description}
                                        </Typography>
                                        <div>
                                            <Typography align={"left"} style={{ fontSize: 18 }} color='primary'>
                                                ¥{purchaseData.items[item].salesPriceWithoutTax}
                                                <span
                                                    style={{ fontSize: 13, marginLeft: 10, color: '#9B9B9B' }}>税込</span>
                                            </Typography>
                                        </div>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <InputAmount itemId={item} setDeleteAlertId={setDeleteAlertId} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DeleteButton handleDeleteClick={() => handleDeleteClick(item)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </>
                        )
                        }

                    </React.Fragment>
                ))}


                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={1}
                      style={{
                          padding: "16px"
                      }}
                >
                    <Grid item xs={4}
                          style={{
                              margin: "auto 0"
                          }}
                    >
                        <Typography
                            style={{
                                fontSize: "0.816rem"
                            }}
                        >
                            支払い金額
                        </Typography>
                    </Grid>
                    <Grid item xs={5}
                          style={{
                              margin: "auto 0"
                          }}>
                        <Typography
                            color={"primary"}
                            style={{
                                fontSize: 22,
                                fontWeight: 900
                            }}
                        >
                            {toYen(purchaseData.sumPrice)}
                            <Typography color={"primary"} component={"span"} variant={"caption"}
                                        style={{
                                            fontSize: "15px",
                                            fontWeight: "600"
                                        }}
                            >
                                税込
                            </Typography>

                        </Typography>
                    </Grid>
                </Grid>
                <BoxTypographyBottom title={"ご利用方法"} />
                <form>
                    <RadioGroup name="orderMethod"
                                onChange={handleChange}
                                value={purchaseData.orderMethod}
                    >
                        <DeliveryOptions />
                    </RadioGroup>
                </form>

                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                >
                    <BoxTypographyBottom title={"支払い方法"} />
                </Grid>
                <form component="fieldset">
                    <RadioGroup value={paymentMethod} onChange={handlePaymegthodChange}>
                        <PaymentMethodOptions />
                    </RadioGroup>
                </form>
                {paymentMethod === "カード" ? (
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            style={{
                                padding: "20px"
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    style={{
                                        fontSize: "0.816rem"
                                    }}
                                >
                                    カード情報
                                </Typography>
                            </Grid>
                            <Grid item align={"right"} xs={7}>
                                {selectedCard ?
                                    <>
                                        <Typography
                                            style={{
                                                fontSize: "0.816rem"

                                            }}
                                        >
                                            {`${selectedCard.brand}  下４桁${selectedCard.last4} ${selectedCard.family_name}${selectedCard.first_name}様`}
                                        </Typography>
                                    </>
                                    :
                                    <Typography>
                                        選択してください
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={1}>
                                <Typography align={"right"}>
                                    <IconButton onClick={() => handlePaymentMethodClick()}>
                                        <ArrowForwardIosIcon fontSize={"small"} />
                                    </IconButton>
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    : null}
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                    style={{
                        padding: "20px"
                    }}
                >
                    <Grid item align={"right"} xs={8}>
                    </Grid>
                </Grid>


                {purchaseData.orderMethod === "delivery" ? (
                        <Grid
                            container
                            direction="row"
                            justify="space-around"
                            alignItems="center"
                            style={{
                                padding: "20px"
                            }}
                        >
                            <Grid item xs={4}>
                                <Typography
                                    style={{
                                        fontSize: "0.816rem"
                                    }}

                                >
                                    配送先
                                </Typography>
                            </Grid>
                            <Grid item align={"right"} xs={7}>
                                {selectedAddress ?
                                    <>
                                        <Typography style={{
                                            fontSize: "0.816rem"

                                        }}>
                                            {`${selectedAddress['family-name-0']}  ${selectedAddress['first-name-1']}様`}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: "0.816rem"

                                            }}
                                        >
                                            {`${selectedAddress['postal-code-0']}  ${selectedAddress['address-level1-1']}  ${selectedAddress['address-level2-2']}  ${selectedAddress['address-level3-3']}`}

                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: "0.816rem"

                                            }}
                                        >
                                            {`${selectedAddress['address-level4-4']}`}
                                        </Typography>
                                    </>
                                    :
                                    <Typography>
                                        選択してください
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={1}>
                                <Typography align={"right"}>
                                    <IconButton onClick={() => handleAddressChangeClick()}>
                                        <ArrowForwardIosIcon fontSize={"small"} />
                                    </IconButton>
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                    : null}
            </div>
            <BottomNavigationEazii
                isDialog={true}
                handleClick={handlePurchaseButtonClick}
                isLoading={purchaseLoading}
                isSuccess={isPurchaseSuccess}
            />
            {deleteAlertId &&
            <DeleteAlert open={deleteAlertId !== ''}
                         handleDeleteNoClick={handleDeleteAlertClose}
                         handleDeleteYesClick={handleDeleteYesClick}
                         message={""}
                         title={"商品を削除しますか"} />
            }
        </>
    );
}
