import { addressType, payjpAddressType } from "./types/address";

// https://pay.jp/docs/api/#card%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88
const convertPayJpAddressObj = (data:addressType)=>{
    let address: payjpAddressType
    address = {
        address_state:data.address_level1,
        //番地など
        address_line1:`${data.address_level2} ${data.address_level3}`,
        // 建物名など
        address_line2:data.address_level4 ? data.address_level4 : "",
        address_zip:data.postal_code,
        country:"JP",
        name:`${data.family_name} ${data.first_name}`,
    }
    return address
}

export {
    convertPayJpAddressObj
}
