import React, { FC } from 'react';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

type DetailButtonProps = {}
const DetailButton: FC<DetailButtonProps> = () => {
    return (
        <Button
            variant="outlined"
            style={{
                borderRadius: 30,
                margin: 5,
                width: 100
            }}
        >
            <Typography color='primary'>商品詳細</Typography>
        </Button>
    )
}
export default DetailButton