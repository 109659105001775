import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingButton from "./LoadingButton";
import firebase, { db } from "../../utils/Firebase";
import { AuthContext } from "../../context/AuthService";
import { purchaseDataState } from "../../atoms/PurchaseData";
import { useRecoilValue } from "recoil";
import { getCaption, switchTxtByEnvUserId, toYen } from "../../utils/utils";
import { selectedAddressInfo } from "../../atoms/addressData"
import { selectedCardInfo } from "../../atoms/cardInfo";
import { paymentMethodState } from '../../atoms/paymentMethodData';
import AlertDialog from './AlertDialog';
import { PurchaseData } from "../../Types";


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    dialogPaper: {
        backgroundColor: "#F2F2F2"
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));

interface BottomNavigationEaziiProps {
    isDialog: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isPaymentMethod: string;
    handleClick: (() => void);
}


const BottomNavigationEazii =
        ({
             isDialog,
             isLoading,
             isSuccess,
             handleClick
         }: BottomNavigationEaziiProps): JSX.Element => {
            //hooks
            const [open, setOpen] = React.useState(false);

            const user = useContext(AuthContext)
            const purchaseData: PurchaseData = useRecoilValue(purchaseDataState);
            const selectedAddress = useRecoilValue(selectedAddressInfo)
            const selectedCard: any = useRecoilValue(selectedCardInfo)
            const paymentMethod = useRecoilValue(paymentMethodState)
            const [dialogOpen, setDialogOpen] = useState(false);
            const deliveryMethodTxtPrefix = switchTxtByEnvUserId()

            const getMessage = () => {
                if (!selectedAddress) {
                    return !purchaseData.orderMethod ? "ご利用方法を選択してください。" : "配送先を選択してください"
                } else {
                    return !purchaseData.orderMethod ? "ご利用方法を選択してください。" : "お支払い方法を選択してください。"

                }
            }

            const handleClose = () => {
                setOpen(false);
            };

            // ダイアログのON/OFF
            const handleDialogOpen = () => {
                setDialogOpen(true);
            };

            const dialogClose = () => {
                setDialogOpen(false);
            };

            const handleClickOpen = () => {
                if (!purchaseData.orderMethod) {
                    handleDialogOpen();
                } else if (purchaseData.orderMethod && paymentMethod === '') {
                    handleDialogOpen();
                } else if (!selectedAddress && paymentMethod === "delivery") {
                    handleDialogOpen()
                } else if (isDialog) {
                    let _purchaseData = JSON.parse(JSON.stringify(purchaseData))
                    for (let item in _purchaseData.items) {
                        if (!_purchaseData.items[item].salesAmount) {
                            delete _purchaseData.items[item]
                        }
                    }


                    let userId = user ? user.id : ""
                    let userName = user ? user.name : ""
                    db.collection("orders/").add({
                        items: _purchaseData.items,
                        sumPrice: _purchaseData.sumPrice,
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        userId: userId ? userId : "",
                        providerId: process.env.REACT_APP_USER_ID,
                        userName: userName ? userName : "",
                        address: selectedAddress,
                        orderMethod: purchaseData.orderMethod,
                        paymentMethod,
                        isProvide: false,
                        tableId: _purchaseData.tableId
                    });
                    return setOpen(true)
                }
            }
            const classes = useStyles();
            // [START useEffect]
            useEffect(() => {

            }, [purchaseData]);
            return (
                    <React.Fragment>

                        <AppBar position="fixed" className={classes.appBar}>
                            <Grid item xs={12}>
                                <Button
                                        onClick={handleClickOpen}
                                        style={{
                                            width: '88%',
                                            margin: "19px 0 13px 13px",
                                            backgroundColor: "#EE5508",
                                            color: "white"
                                        }}>
                                    購入手続きへ
                                </Button>
                                <AlertDialog
                                        open={dialogOpen}
                                        onClose={dialogClose}
                                        title=""
                                        message={getMessage()}
                                />
                            </Grid>
                        </AppBar>
                        <Dialog fullWidth={true}
                                maxWidth='xs' onClose={handleClose} aria-labelledby="customized-dialog-title"
                                open={open}
                                disableEscapeKeyDown={true}
                                disableBackdropClick={true}
                                PaperProps={{
                                    style: {
                                        backgroundColor: "#F2F2F2"
                                    },
                                    classes: { root: classes.dialogPaper }
                                }}
                        >
                            <DialogTitle id="customized-dialog-title"
                                         style={{
                                             backgroundColor: "#F2F2F2"
                                         }}
                            >
                                <Typography align={"center"}
                                >
                                    {deliveryMethodTxtPrefix}確認
                                </Typography>
                            </DialogTitle>
                            <DialogContent dividers
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography>
                                            {deliveryMethodTxtPrefix}金額
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {toYen(purchaseData.sumPrice)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogTitle>
                                {purchaseData.orderMethod === "delivery" ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    配送先
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {selectedAddress ?
                                                        <Typography>
                                                            {`${selectedAddress['family-name-0']}  ${selectedAddress['first-name-1']}様`}<br />
                                                            {`${selectedAddress['postal-code-0']}  ${selectedAddress['address-level1-1']}  ${selectedAddress['address-level2-2']}  ${selectedAddress['address-level3-3']}  ${selectedAddress['address-level4-4']}`}
                                                        </Typography>
                                                        :
                                                        <Typography>
                                                            配送先を選択してください
                                                        </Typography>
                                                }
                                            </Grid>
                                        </Grid> : null}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={5}>
                                        <Typography>
                                            支払い方法
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography>
                                            {paymentMethod}
                                        </Typography>
                                        {paymentMethod === "カード" && (
                                                <Typography>
                                                    {`${selectedCard.brand}  下４桁${selectedCard.last4} `}
                                                    <Typography>
                                                        {`${selectedCard.family_name}${selectedCard.first_name}様`}
                                                    </Typography>
                                                </Typography>
                                        )}
                                    </Grid>
                                </Grid>

                                <Typography variant={"caption"}>
                                    {getCaption(purchaseData)}
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton
                                        handleClick={handleClick}
                                        setClose={handleClose}
                                        isLoading={isLoading}
                                        isSuccess={isSuccess}
                                />
                            </DialogActions>

                        </Dialog>
                    </React.Fragment>
            );
        }
export default BottomNavigationEazii;
