import React, { FC } from "react";

import { makeStyles } from "@material-ui/core/styles";
import MaterialAlert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Info } from '../../assets/icons/info.svg'
import { ReactComponent as Notification } from '../../assets/icons/notification.svg'
import { ReactComponent as AlertOutline } from '../../assets/icons/alertOutline.svg'

type AlertType = 'err' | 'warning' | 'notice';

export interface EaziiAlertProps {
    type: AlertType
    title: string;
}

const EaziiAlert: FC<EaziiAlertProps> = ({ type, title, children }): JSX.Element => {
    const useStyles = makeStyles(() => ({
        root: {
            width: "95%",
            margin: "0 auto",
            marginTop: 10,
        },
        internal: {
            fontWeight: "bold",
            fontSize: 13,
        },
        details: {
            fontSize: 12,
            whiteSpace: 'normal',
        },
    }));

    const classes = useStyles();

    /** 色選択 */
    const chooseColor = (color: AlertType) => {
        switch (color) {
            case 'err':
                return { border: ' 1px solid #FFB7B7', backgroundColor: '#FFEFEF' }
            case 'warning':
                return { border: ' 1px solid #E6BA9E', backgroundColor: '#FFF5EF' }
            case 'notice':
                return { border: ' 1px solid #A1E69E', backgroundColor: '#EFFFF6' }
            default :
                return null
        }
    }

    /** アイコン選択 */
    const chooseIcon = (type: AlertType) => {
        switch (type) {
            case 'err' :
                return <AlertOutline style={{ height: '16px', width: '16px', fill: '#ff8ec6', marginRight: '5px' }} />
            case 'warning' :
                return <Info style={{ height: '16px', width: '16px', fill: '#ffbf7f', marginRight: '5px' }} />
            case 'notice' :
                return <Notification style={{ height: '16px', width: '16px', fill: '#31C974', marginRight: '5px' }} />
            default:
                return null
        }
    }

    return (
            <div className={classes.root}>
                <MaterialAlert
                        style={{ padding: '0px 12px 5px', height: "70px", ...chooseColor(type) }}
                        icon={false}
                >
                    <div>
                        <Typography color="primary" className={classes.internal}>
                            {chooseIcon(type)}
                            {title}
                        </Typography>
                        <Typography color="primary" className={classes.details}>
                            {children}
                        </Typography>
                    </div>
                </MaterialAlert>
            </div>
    );
};

export default EaziiAlert

         




