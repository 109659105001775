import { atom } from 'recoil';


export const purchaseDataState = atom({
    key: 'purchaseDataState',
    default: {
        items: {},
        totalSalesAmount:0,
        sumPrice: 0,
        orderMethod:"",
        tableId:0
    },
})

export const liffDataState = atom({
    key: 'liffDataState',
    default: {
        utouId: "xxx"
    }
})
