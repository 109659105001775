import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LoadingButton from "./LoadingButton";
import { getCaption, gray1, switchTxtByEnvUserId } from "../../utils/utils";
import { useRecoilValue } from "recoil";
import { purchaseDataState } from "../../atoms/PurchaseData";
import { paymentMethodState } from '../../atoms/paymentMethodData';


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    grow: {
        flexGrow: 1,
    },
    dialogPaper: {
        backgroundColor: "#F2F2F2"
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));

function jaPrice(price:number) {
    return new Intl.NumberFormat('ja-JP', {style: 'currency', currency: 'JPY'}).format(price)
}


export default function BottomClose():JSX.Element {

    //hooks
    const [open, setOpen] = React.useState(false);

    const history = useHistory();

    const _purchasedData = useRecoilValue(purchaseDataState);

    const paymentMethod = useRecoilValue(paymentMethodState)
    const deliveryMethodTxtPrefix = switchTxtByEnvUserId()

    /*
     TODO:購入処理
     DB連携等
     */
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        history.goBack();
    }
    const classes = useStyles();
    const location:any = useLocation();
    // [START useEffect]
    useEffect(() => {

    }, []);
    return (
        <React.Fragment>

            <AppBar position="fixed"  className={classes.appBar}>
                <Button

                    onClick={handleClickOpen}
                    style={{
                        width: "58%",
                        margin: "13px auto 10px",
                        backgroundColor: gray1,
                        color: "white"
                    }}>
                    閉じる
                </Button>
            </AppBar>
            <Dialog fullWidth={true}
                    maxWidth='xs' onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    PaperProps={{
                        style: {
                            backgroundColor: "#F2F2F2"
                        },
                        classes: {root: classes.dialogPaper}
                    }}
            >
                <DialogTitle id="customized-dialog-title" 
                             style={{
                                 backgroundColor: "#F2F2F2"
                             }}
                >
                    <Typography align={"center"}
                    >
                        {deliveryMethodTxtPrefix}確認
                    </Typography>
                </DialogTitle>
                <DialogContent dividers
                >
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography>
                                {deliveryMethodTxtPrefix}金額
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                {_purchasedData.sumPrice ? jaPrice(_purchasedData.sumPrice + 15000) : jaPrice(location.state ? location.state.sumPrice + 15000 : 15000)}
                            </Typography>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <Typography>
                                支払い方法
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography>
                                {paymentMethod ? paymentMethod : "請求書払い"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant={"caption"}>
                        {getCaption(_purchasedData)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        setClose={handleClose}
                        handleClick={handleClickOpen}/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
