import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';


var firebaseConfig = {
    apiKey: "AIzaSyAcduvm2xNN4eMR_c0TFvnx5aCiHSY8vW0",
    authDomain: "linebuy.firebaseapp.com",
    databaseURL: "https://linebuy.firebaseio.com",
    projectId: "linebuy",
    storageBucket: "linebuy.appspot.com",
    messagingSenderId: "833647912131",
    appId: "1:833647912131:web:dfaf65096eb236d8f6ee76",
    measurementId: "G-31WB1DXG8F"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();