import React from "react";


const PayJp = () => {

    return (
        <>
            <div id="v2-demo" className="payjs-outer" style={{
                border: "thin solid #198fcc"
            }}/>
        </>
    )
}
export default PayJp;