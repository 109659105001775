import React from 'react'
import RadioControl from "../molecules/Radio/RadioControl";
import { ReactComponent as DeliveryIcon } from "../assets/icons/delivery.svg";
import { ReactComponent as TakeoutIcon } from "../assets/icons/bag.svg";
import { ReactComponent as EatInIcon } from "../assets/icons/fork.svg";

const switchProvideOption = () => {
    switch (process.env.REACT_APP_PROVIDE_OPTION) {
        case "all":
            return (
                    <>
                        <RadioControl radioValue={'請求書払い'}
                                      radioLabelText={'請求書払い'}
                        />
                        <RadioControl radioValue={'店内で会計'}
                                      radioLabelText={'店内で会計'}
                        />
                        <RadioControl radioValue={'カード'}
                                      radioLabelText={'クレジットカード'}
                        />
                    </>
            )
        case "deliveryOnly":
            return (
                    <>
                        <RadioControl radioValue={'請求書払い'}
                                      radioLabelText={'請求書払い'}
                        />
                        <RadioControl radioValue={'カード'}
                                      radioLabelText={'クレジットカード'}
                        />
                    </>
            )
        case "eatInOnly":
            return (
                    <>
                        <RadioControl radioValue={'店内で会計'}
                                      radioLabelText={'店内で会計'}
                        />
                    </>
            )
        default :
            return (
                    <>
                        <RadioControl radioValue={'店内で会計'}
                                      radioLabelText={'店内で会計'}
                        />
                        <RadioControl radioValue={'カード'}
                                      radioLabelText={'クレジットカード'}
                        />
                    </>
            )
    }
}

const PaymentMethodOptions = () => {
    return (
            <>
                {switchProvideOption()}
            </>
    )
}

export default PaymentMethodOptions