import React, { FC } from 'react'
import Grid from "@material-ui/core/Grid";
import RepurchaseButton from "../atoms/Button/RepurchaseButton";
import DetailButton from "../atoms/Button/DetailButton";

type OrderedButtonsProps = {
    repurchase: (() => void)
}
const OrderedButtons: FC<OrderedButtonsProps> = ({repurchase}) => {
    return (
        <>
            <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <RepurchaseButton repurchase={repurchase}/>
                </Grid>
                <Grid item xs={12}>
                    <DetailButton/>
                </Grid>
            </Grid>
        </>
    )
}
export default OrderedButtons