import React, { FC, useEffect, useState } from 'react';
import './App.css';
import Purchase from "./component/pages/Purchase";
import Main from "./component/pages/Main.jsx";
import Orders from "./component/pages/Orders";
import Order from "./component/pages/Order";
import UserCard from './component/pages/UserCard';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RecoilRoot, } from 'recoil';
import firebase from "./utils/Firebase";
import AuthProvider from "./context/AuthService";
import AddressForm from "./component/pages/AddressForm";
import Addresses from "./component/pages/Addresses";
import ItemDetail from "./component/pages/ItemDetail.jsx";
import { useCollection } from "react-firebase-hooks/firestore";
import { CopyClip } from "./component/pages/CopyClip";
import PaymentMethodEdit from "./component/pages/PaymentMethodEdit";
import PaymentMethod from "./component/pages/PaymentMethod";
import { theme } from "./styles/theme";
import { ThemeProvider } from '@material-ui/core/styles';
import OrderComplete from './component/pages/OrderComplete';

const App: FC = () => {
    const [purchaseData, setPurchaseData] = useState({});


    const [value1] = useCollection(
            firebase.firestore().collection("items").where("userId", "==", process.env.REACT_APP_USER_ID).where("createdAt", ">=", new Date("2020/05/01")).orderBy("createdAt", "desc"),
            {
                snapshotListenOptions: { includeMetadataChanges: true },
            }
    );

    useEffect(() => {
        if (value1 && !Object.keys(purchaseData).length) {
            let _data: object = {}
            for (let _doc of value1.docs) {
                // @ts-ignore
                _data[_doc.id] = {}
                for (let item in _doc.data()) {
                    // @ts-ignore
                    _data[_doc.id][item] = _doc.data()[item]
                }
            }
            // @ts-ignore
            _data['sumPrice'] = 0
            setPurchaseData(_data)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <AuthProvider>
                        <Router basename={"/"}>
                            <Switch>
                                <Route exact path="/address/edit">
                                    <AddressForm />
                                </Route>
                                <Route exact path="/">
                                    <Main />
                                </Route>
                                <Route exact path="/tables/:tableId">
                                    <Main />
                                </Route>
                                <Route exact path="/confirm">
                                    <Purchase />
                                </Route>
                                <Route exact path="/orders/:id">
                                    <Order />
                                </Route>
                                <Route exact path={"/copy"}>
                                    <CopyClip />
                                </Route>

                                <Route exact path="/items/:id">
                                    <ItemDetail />
                                </Route>
                                <Route exact path="/address">
                                        <Addresses />
                                </Route>
                                <Route exact path="/orders">
                                    <Orders />
                                </Route>

                                <Route exact path={"/paymentmethod"}>
                                    <PaymentMethod />
                                </Route>
                                <Route exact path={"/paymentmethod/edit"}>
                                    <PaymentMethodEdit />
                                </Route>
                                <Route exact path='/usercard'>
                                    <UserCard />
                                </Route>
                                <Route exact path='/ordercomplete'>
                                    <OrderComplete />
                                </Route>
                            </Switch>
                        </Router>
                    </AuthProvider>
                </ThemeProvider>
            </RecoilRoot>
    );
}

export default App;
