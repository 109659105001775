import React, { FC } from "react";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "#F2F1F0",
        width: "100%",
        height: "70px",
    },
    titleBoxText: {
        color: "#442D29",
        position: "absolute",
        bottom: 12,
        left: 14,
        fontSize: 13
    }
}));
type BoxTypographyBottomProps = { title: string }
const BoxTypographyBottom: FC<BoxTypographyBottomProps> = ({title}) => {

    const classes = useStyles();
    return (
        <>

            <Box className={classes.root} position={"relative"}>
                <Typography classes={{root: classes.titleBoxText}}>
                    {title}
                </Typography>
            </Box>
        </>
    )
}
export default BoxTypographyBottom
