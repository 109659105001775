import React, { useContext, useEffect, useState } from 'react';
import MyHeader from '../molecules/MyHeader';
import { AuthContext } from '../../context/AuthService';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from '../../utils/Firebase';
import { useSetRecoilState } from 'recoil';
import { selectedCardInfo } from '../../atoms/cardInfo';
import { useHistory } from 'react-router-dom';
import Progress from '../atoms/Progress/Progress';
import shortid from 'shortid';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DoneIcon from '@material-ui/icons/Done';
import { theme } from "../../styles/theme";
import DeleteIcon from "@material-ui/icons/Delete"
import DeleteAlert from "../molecules/Alert/DeleteAlert";

const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px 0 20px 22px',
    },
    btnContainer: {
        textAlign: 'center',
        padding: '20px 25px 0',
    },
}));

export default function Address() {
    const classes = useStyles();
    const user = useContext(AuthContext);
    const [deleteAlertId, setDeleteAlertId] = useState('');
    const [value, loading, error] = useCollection(
        firebase
            .firestore()
            .collection(`member/`)
            .where(
                'userId',
                '==',
                user ? user.id : 'U9bebb7bfa67b98a00b173a4a4045898c'
            ),
        {}
    );
    const [cardInfo, setCardInfo] = useState<Array<any>>([]);
    const setSelectedCardInfo = useSetRecoilState(selectedCardInfo);
    useEffect(() => {
        if (value) {
            value.docs[0].ref
                .collection('card')
                .get()
                .then(querySnapshot => {
                    let newCardinfo: Array<object> = [];
                    querySnapshot.docs.forEach((el, index) => {
                        newCardinfo = [...newCardinfo, {...el.data(), id: el.id}];
                    });
                    setCardInfo(newCardinfo);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [value]);
    useEffect(() => {
        if (value) {
            value.docs[0].ref
                .collection('card')
                .get()
                .then(querySnapshot => {
                    const checkedIndex = cardInfo.findIndex(el => el.defaultCheck);
                    let i = 0;
                    querySnapshot.forEach(doc => {
                        if (i === checkedIndex) {
                            // eslint-disable-next-line react-hooks/exhaustive-deps
                            doc.ref.update({defaultCheck: true});
                            setSelectedCardInfo({...doc.data(), defaultCheck: true});
                        } else {
                            doc.ref.update({defaultCheck: false});
                        }
                        i++;
                    });
                });
        }
    }, [cardInfo, value]);
    const history = useHistory();

    const handleDeleteClick = (itemId:string) => {
        setDeleteAlertId(itemId)
    };

    const handleDeleteYesClick = ()=>{
        if (value) {
            value.docs[0].ref
                .collection('card').doc(deleteAlertId).delete()
                .then(() => {
                    window.location.reload()
                }).catch(err => {

            })
        }
    }
    const handleDeleteAlertClose = ()=>{
        setDeleteAlertId('')
    }

    const handleNewClick = () => {
        history.push('/paymentmethod/edit')
    }
    const changeDoneIcon = (id: string) => {
        const newCardinfo = cardInfo.map(el => {
            if (el.id === id) {
                setSelectedCardInfo({...el, defaultCheck: true});
                return {...el, defaultCheck: true};
            } else {
                return {...el, defaultCheck: false};
            }
        });
        setCardInfo(newCardinfo);
    };
    return (
        <>
            <MyHeader title={'お支払い方法'}/>
            <Paper>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <Progress/>}
                {value && cardInfo && (
                    <>
                        {cardInfo.map(el => {
                            return (
                                <div key={shortid.generate()}>
                                    <Grid
                                        container
                                        justify='space-between'
                                        className={classes.container}
                                        style={
                                            el.defaultCheck === true
                                                ? {border: 'solid 1px #000'}
                                                : {border: 'none'}
                                        }
                                    >
                                        <Grid item xs={8}
                                              onClick={() => changeDoneIcon(el.id)}
                                        >
                                            <Typography>
                                                {`${el['brand']}  ${el['last4']}  ${el['family_name']}  ${el['first_name']}`}
                                                <br/>
                                                {`${el['postal_code']}  ${el['address_level1']}  ${el['address_level2']}  ${el['address_level3']}  ${el['address-level4']}`}


                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}
                                              style={{
                                                  display: 'inline-flex',
                                                  verticalAlign: 'middle'
                                              }}
                                        >
                                            {el.defaultCheck &&
                                            <DoneIcon fontSize="large" style={{
                                                fontWeight: 800,
                                                margin: 'auto auto auto auto',
                                                color: theme.palette.secondary.main
                                            }}/>
                                            }
                                        </Grid>
                                        <Grid item xs={2}
                                              onClick={() => handleDeleteClick(el.id)}
                                        >
                                            <IconButton
                                                style={{marginLeft: '10px'}}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Divider/>
                                </div>
                            );
                        })}
                    </>
                )}
            </Paper>
            <div className={classes.btnContainer}>
                <Button
                    fullWidth
                    variant='outlined'
                    color='primary'
                    onClick={() => handleNewClick()}
                >
                    新規登録
                </Button>
            </div>
            {deleteAlertId &&
            <DeleteAlert open={deleteAlertId !== ''}
                         handleDeleteNoClick={handleDeleteAlertClose}
                         handleDeleteYesClick={handleDeleteYesClick}
                         message={""}
                         title={"カード情報を削除しますか"}/>
            }

        </>
    );
}
