import React, { useEffect, useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import CopyToClipBoard from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { liffDataState } from "../../atoms/PurchaseData";
import { useRecoilState } from "recoil";


let liff = window.liff

export function CopyClip() {
    const [liffData, setLiffData] = useRecoilState(liffDataState);
    const [openTip, setOpenTip] = useState(false);
    const handleClickButton = () => {
        setOpenTip(true);
    };
    const handleCloseTip = () => {
        setOpenTip(false);
    };
    useEffect(() => {
        liff.init({liffId: process.env.REACT_APP_LIFF_ID}).then(() => {
            setLiffData(liff.getContext());
        }).catch(function (error) {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <OutlinedInput
            type='text'
            disabled
            value={liffData ? liffData.utouId : ""}
            endAdornment={
                <InputAdornment position="end">
                    <Tooltip
                        arrow
                        open={openTip}
                        onClose={handleCloseTip}
                        disableHoverListener
                        placement='top'
                        title='Copied!'
                    >
                        <CopyToClipBoard text={liffData ? liffData.utouId : ""}>
                            <IconButton
                                onClick={handleClickButton}
                            >
                                <AssignmentIcon/>
                            </IconButton>
                        </CopyToClipBoard>
                    </Tooltip>
                </InputAdornment>
            }
        />

    )
}
