import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDocument } from 'react-firebase-hooks/firestore';
import { db } from "../../utils/Firebase.js";
import Progress from "../atoms/Progress/Progress";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MyImageGallery from "../atoms/MyImageGallery.jsx";
import { lightGray, strongGray, toYen } from "../../utils/utils.js";
import BoxTypographyBottom from "../atoms/BoxTypographyBottom.tsx";
import Divider from "@material-ui/core/Divider";
import { useForm } from "react-hook-form";
import InputAmount from "../atoms/InputAmount";
import { useRecoilState, useRecoilValue } from "recoil";
import { purchaseDataState } from "../../atoms/PurchaseData";
import BottomNavigationGroup from "../molecules/BottomNavigationGroup";
import { dateToFormatString } from "../../utils/utils"
import AlertDialog from "../molecules/AlertDialog"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: "120px",/*←footerの高さ*/
    },
    paper: {
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    titleBox: {
        backgroundColor: lightGray,
        width: "100%",
        height: "60px",
    },
    titleBoxText: {
        color: strongGray,
        position: "absolute",
        bottom: 0
    },
    itemName: {
        fontSize: "27px",
        fontWeight: "bold",
        paddingLeft: "15px",
        paddingRight: "15px",
    },
    endDate: {
        fontSize: "13px",
        fontWeight: "bold",
        color: "#FF8C3E",
        paddingLeft: "15px",
    },
    price: {
        color: "#442D29",
        fontSize: "26px",
        fontWeight: "bold",
        paddingTop: "10px",
        paddingLeft: "15px",
    },
    desc: {
        fontSize: "12px",
        color: "#442D29",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "30px"
    },
    detail: {
        fontSize: "12px",
        padding: "15px",
        color: "#442D29"
    }
}));

export default function ItemDetail() {

    const { id } = useParams();
    console.log(id)
    const history = useHistory();
    const [purchaseData, setPurchaseData] = useRecoilState(purchaseDataState);
    const _purchasedData = useRecoilValue(purchaseDataState);
    const { register, handleSubmit } = useForm();
    const classes = useStyles();
    const [value, loading, error] = useDocument(
            db.doc(`items/${id}`),
            {
                snapshotListenOptions: { includeMetadataChanges: true },
            }
    );
    const [images, setImages] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);


    const onSubmit = () => {
        let AmountTotal = 0;
        for (const property in _purchasedData.items) {
            AmountTotal += _purchasedData.items[property].salesAmount;
        }
        if (!AmountTotal) {
            handleDialogOpen(true);
        } else {
            history.push({
                state: purchaseData,
                pathname: "/confirm"
            })
        }
    }
    // TODO switch系の処理は一括管理
    const switchByDeliveryOption = () => {
        // TODO Atomic design
        // TODO その他のパターンにも対応
        switch (process.env.REACT_APP_PROVIDE_OPTION) {
            case 'all':
                return (
                        <>
                        </>
                )
            case "deliveryOnly":
                return (
                        <>
                            <Divider style={{ height: "1px", width: "100%", backgroundColor: "#E0E0E0" }} />
                            <Grid item xs={6}>
                                <Typography className={classes.detail} style={{ paddingLeft: "20px" }}>
                                    配送料
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.detail}>
                                    {value.data().deliveryFeeWithoutTax}
                                </Typography>
                                <Typography
                                        vairant="subtitle1"
                                        style={{
                                            fontSize: "10px",
                                            color: "#442D29",
                                            paddingRight: "10px"
                                        }}
                                >
                                    ※トラック1台分の送料です。積みきれない場合、送料を調整させていただく場合がございます
                                </Typography>
                            </Grid>
                            <Divider style={{ height: "1px", width: "100%", backgroundColor: "#E0E0E0" }} />
                            <Grid item xs={6}>
                                <Typography className={classes.detail} style={{ paddingLeft: "20px" }}>
                                    お届け予定日
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.detail}>
                                    ご注文確定より2日後
                                </Typography>
                            </Grid>

                        </>
                )
        }
    }

    // ダイアログのON/OFF
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const dialogClose = () => {
        setDialogOpen(false);
    };


    // [START useEffect]
    useEffect(() => {

        if (value) {
            if (!Object.keys(purchaseData.items).length) {
                let _data = {}
                _data["items"] = {}

                _data["items"][id] = { salesAmount: 0 }
                for (let item in value.data()) {
                    _data["items"][id][item] = value.data()[item]

                }
                _data["items"][id]["salesAmount"] = 0
                _data['sumPrice'] = 0
                setPurchaseData(_data)
            }
            let _images =
                    value.data().imageUrls.map(item => {
                        let obj = {
                            original: item,
                            thumbnail: item,
                            sizes: "33%"
                        }
                        return obj
                    })
            setImages(_images)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, purchaseData])
    // [END useEffect]
    if (value) {
        console.log(value.data())
    }
    return (
            <>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <Progress />}
                {value && (
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={classes.root}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <MyImageGallery
                                                    images={images}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.itemName}>
                                                {value.data().itemName}
                                            </Typography>
                                            <Typography className={classes.endDate}>
                                                {dateToFormatString(value.data().endAt.toDate(), '%M%月%DD%日 %HH%:%mm%')}まで
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography
                                                    variant="h4"
                                                    className={classes.price}
                                            >
                                                {toYen(value.data().salesPriceWithoutTax)}
                                                <span style={{ fontSize: "13px", color: "#9B9B9B" }}>  税抜</span>
                                            </Typography>
                                            {/* <LabelWithBackgroundColor text={"受付中"}/> */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography align="right"
                                                        style={{
                                                            fontSize: "13px",
                                                            color: "#442D29",
                                                            marginTop: "20px"
                                                        }}>
                                                数量
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <InputAmount register={register} itemId={id} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <BoxTypographyBottom title={"商品の説明"} />
                                        </Grid>
                                        <Grid item xs={12}
                                              style={{
                                                  margin: "auto 0"
                                              }}
                                        >
                                            <Typography
                                                    align={"left"}
                                                    variant="subtitle1"
                                                    className={classes.desc}
                                            >
                                                {value.data().description}
                                            </Typography>
                                        </Grid>
                                        <Divider style={{ height: "1px", width: "100%", backgroundColor: "#E0E0E0" }} />
                                        <Grid item xs={6}>
                                            <Typography className={classes.detail} style={{ paddingLeft: "20px" }}>
                                                カテゴリー
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography className={classes.detail}>
                                                {value.data().category.title}
                                            </Typography>
                                        </Grid>
                                        {switchByDeliveryOption()}
                                        <Divider style={{ height: "1px", width: "100%", backgroundColor: "#E0E0E0" }} />
                                    </Grid>
                                </div>
                                <BottomNavigationGroup />
                                <AlertDialog
                                        open={dialogOpen}
                                        onClose={dialogClose}
                                        title="EaziiAlert!!"
                                        message="カートが空です！"
                                />
                            </form>
                        </>
                )
                }
            </>
    )
}
