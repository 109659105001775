import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MyHeader from "../molecules/MyHeader";


const UserCard = () => {

    const useStyles = makeStyles({
        root: {
            maxWidth: "100%",
        },
        media: {
            height: 140,
        },
        main: {
            width: "80%",
            margin: "34px auto"
        }
    });
    const classes = useStyles();
    return (
        <React.Fragment>
            <MyHeader title={"ポイントカード"}/>
            <main className={classes.main}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        <Card className={classes.root}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image="https://picsum.photos/200/300"
                                    title="Contemplative Reptile"
                                />
                            </CardActionArea>
                            <CardContent>
                                <Typography
                                    variant="h3"
                                    component="h2"
                                    align={"right"}
                                    fontSize={16}
                                    style={styles.rightText}
                                >
                                    214<span style={styles.pointText}>pt</span>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </main>
        </React.Fragment>
    )
};

const styles = {
    border: {
        width: '70%',
        border: '1px solid whitesmoke',
        borderRadius: '10px'

    },
    borderWrap: {
        marginTop: '20px',
        marginBottom: '30px'

    },
    bottomWrap: {
        width: '100%',
        height: '15vh',
        border: '1px solid silver',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

    },
    wrap: {

        width: '100%',
        height: '100vh',
    },
    cardImage: {
        width: '100%',
        height: '20vh',
        borderRadius: '10px'
    },
    rightText: {

        marginRight: '25px'
    },
    pointText: {
        fontSize: '35px'
    },
    smallImage: {
        width: '50px',
        height: '50px',
        marginLeft: '15px'
    },
    topWrap: {
        width: '100%',
        height: '30vh',
    }

};


export default UserCard;
