import React, { FC } from 'react'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

type RepurchaseButtonProps = {
    repurchase: (() => void)
}
const RepurchaseButton: FC<RepurchaseButtonProps> = ({repurchase}) => {
    return (
        <Button variant="outlined" onClick={repurchase}
                style={{
                    borderRadius: 30,
                    margin: 5,
                    width: 100,
                }}
        >
            <Typography color='primary'>再購入</Typography>
        </Button>

    )
}
export default RepurchaseButton