import firebase, { db } from "./Firebase"
import { PurchaseData } from "../Types";

export function dateToFormatString(date, fmt, locale, pad) {
    // %fmt% を日付時刻表記に。
    // 引数
    //  date:  Dateオブジェクト
    //  fmt:   フォーマット文字列、%YYYY%年%MM%月%DD%日、など。
    //  locale:地域指定。デフォルト（入力なし）の場合はja-JP（日本）。現在他に対応しているのはen-US（英語）のみ。
    //  pad:   パディング（桁数を埋める）文字列。デフォルト（入力なし）の場合は0。
    // 例：2016年03月02日15時24分09秒
    // %YYYY%:4桁年（2016）
    // %YY%:2桁年（16）
    // %MMMM%:月の長い表記、日本語では数字のみ、英語ではMarchなど（3）
    // %MMM%:月の短い表記、日本語では数字のみ、英語ではMar.など（3）
    // %MM%:2桁月（03）
    // %M%:月（3）
    // %DD%:2桁日（02）
    // %D%:日（2）
    // %HH%:2桁で表した24時間表記の時（15）
    // %H%:24時間表記の時（15）
    // %h%:2桁で表した12時間表記の時（03）
    // %h%:12時間表記の時（3）
    // %A%:AM/PM表記（PM）
    // %A%:午前/午後表記（午後）
    // %mm%:2桁分（24）
    // %m%:分（24）
    // %ss%:2桁秒（09）
    // %s%:秒（9）
    // %W%:曜日の長い表記（水曜日）
    // %w%:曜日の短い表記（水）
    var padding = function(n, d, p) {
        p = p || '0';
        return (p.repeat(d) + n).slice(-d);
    };
    var DEFAULT_LOCALE = 'ja-JP';
    var getDataByLocale = function(locale, obj, param) {
        var array = obj[locale] || obj[DEFAULT_LOCALE];
        return array[param];
    };
    var format = {
        'YYYY': function() { return padding(date.getFullYear(), 4, pad); },
        'YY': function() { return padding(date.getFullYear() % 100, 2, pad); },
        'MMMM': function(locale) {
            var month = {
                'ja-JP': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                'en-US': ['January', 'February', 'March', 'April', 'May', 'June',
                    'July', 'August', 'September', 'October', 'November', 'December'],
            };
            return getDataByLocale(locale, month, date.getMonth());
        },
        'MMM': function(locale) {
            var month = {
                'ja-JP': ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                'en-US': ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'June',
                    'July', 'Aug.', 'Sept.', 'Oct.', 'Nov.', 'Dec.'],
            };
            return getDataByLocale(locale, month, date.getMonth());
        },
        'MM': function() { return padding(date.getMonth()+1, 2, pad); },
        'M': function() { return date.getMonth()+1; },
        'DD': function() { return padding(date.getDate(), 2, pad); },
        'D': function() { return date.getDate(); },
        'HH': function() { return padding(date.getHours(), 2, pad); },
        'H': function() { return date.getHours(); },
        'hh': function() { return padding(date.getHours() % 12, 2, pad); },
        'h': function() { return date.getHours() % 12; },
        'mm': function() { return padding(date.getMinutes(), 2, pad); },
        'm': function() { return date.getMinutes(); },
        'ss': function() { return padding(date.getSeconds(), 2, pad); },
        's': function() { return date.getSeconds(); },
        'A': function() {
            return date.getHours() < 12 ? 'AM' : 'PM';
        },
        'a': function(locale) {
            var ampm = {
                'ja-JP': ['午前', '午後'],
                'en-US': ['am', 'pm'],
            };
            return getDataByLocale(locale, ampm, date.getHours() < 12 ? 0 : 1);
        },
        'W': function(locale) {
            var weekday = {
                'ja-JP': ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
                'en-US': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            };
            return getDataByLocale(locale, weekday, date.getDay());
        },
        'w': function(locale) {
            var weekday = {
                'ja-JP': ['日', '月', '火', '水', '木', '金', '土'],
                'en-US':  ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            };
            return getDataByLocale(locale, weekday, date.getDay());
        },
    };
    var fmtstr = ['']; // %%（%として出力される）用に空文字をセット。
    Object.keys(format).forEach(function(key) {
        fmtstr.push(key); // ['', 'YYYY', 'YY', 'MMMM',... 'W', 'w']のような配列が生成される。
    })
    var re = new RegExp('%(' + fmtstr.join('|') + ')%', 'g');
    // /%(|YYYY|YY|MMMM|...W|w)%/g のような正規表現が生成される。
    var replaceFn = function(match, fmt) {
        // match には%YYYY%などのマッチした文字列が、fmtにはYYYYなどの%を除くフォーマット文字列が入る。
        if(fmt === '') {
            return '%';
        }
        var func = format[fmt];
        // fmtがYYYYなら、format['YYYY']がfuncに代入される。つまり、
        // function() { return padding(date.getFullYear(), 4, pad); }という関数が代入される。
        if(func === undefined) {
            //存在しないフォーマット
            return match;
        }
        return func(locale);
    };
    return fmt.replace(re, replaceFn);
}
export function millisToFormatString(millis){
    return dateToFormatString(new Date(millis),'%YYYY%年%MM%月%DD%日 %HH%:%mm%')
}

export function toYen(price,name){
    return  new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' ,currencyDisplay:name?name:"symbol"}).format(price?price:0)
}
export function removeTrailingSlash(url) {
    return url.replace(/\/$/, '')
}

export const switchTxtByEnvUserId =()=>{
    switch (process.env.REACT_APP_USER_ID){
        case "r6vOZDm2jcfTPZXMRhrBq7tOoW53":
            return "ご注文"
        default :
            return "ご注文"
    }
}

export const getCaption = (purchaseData) => {
    switch (purchaseData.orderMethod){
        case "delivery":
            return ""
        case "eatIn":
            if(purchaseData.tableId===0){
                return `テーブル番号:なし`
            }else{
                return `テーブル番号:${purchaseData.tableId}`
            }
        case "takeout":
            return "お持ち帰り"
        default:
            return "";
    }
}


export const updateAddressinfo = ()=>{
  const now = new Date()
  firebase.firestore().collection("member").where("userId","==","U9bebb7bfa67b98a00b173a4a4045898c")
    .get().then(async(querySnapshot)=>{
    const ref = querySnapshot.docs[0].ref.collection("address")
    let addressDatas = []
    const addressInfos = await ref.get()
    addressInfos.forEach((doc)=>{
      addressDatas = [...addressDatas, {createdAt: now, ...doc.data()}]
      ref.doc(doc.id).delete()
    })
    addressDatas.forEach((el,index)=>{
      if(index === 0) {
          ref.doc().set({...el, defaultCheck:true})
        } else {
          ref.doc().set({...el, defaultCheck:false})
        }
    })
  })
}


export const deleteItems = () => {
    let start = new Date('2020-07-22');
    let end = new Date('2020-08-11');
    db.collection("member").doc("f3XzzXpSAZshGskxG6PW").collection("address").where("createdAt", ">", start)
        .where("createdAt","<",end).get()
        .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                doc.ref.delete().then(function () {
                    console.log("Document successfully deleted!");
                }).catch(function (error) {
                    console.error("Error removing document: ", error);
                });

            })
        })

}
export const payjpHTTPStatusToMessage = (err)=>{
  const {status} = err
  switch(status) {
    case 400:
      return "不正なパラメーターなどのリクエストエラー"
    case 401:
        return "APIキーの認証エラー"
    case 402:
      return "カード認証・支払いエラー"
    case 404:
      return "存在しないAPIリソース"
    case 500:
      return "PAY.JPや決済ネットワークでの障害"
    default:
      return "不明なエラー"
  }
}

export const getTitleByProvideOption = () =>{
    switch(process.env.REACT_APP_PROVIDE_OPTION){
        case "eatInOnly":
            return "ご注文を受け付けました。"
        default :
            return "ご注文を受け付けました。"
    }
}

export const getImgByProvideOption = () =>{
    switch(process.env.REACT_APP_PROVIDE_OPTION){
        case "eatInOnly":
            return "mobile-order-logo.svg"
        default :
            return "eazii-cart-logo.svg"
    }
}
export const gray = "#D0CECE";
export const gray1= "#A6A6A6";
export const orangeStrong = "#EE5508";
export const lightGray= "#F2F2F2";
export const strongGray = "#A2A2A2";
