import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router";
import shortid from "shortid";
import EaziiItemCard from "../molecules/Card/EaziiItemCard";
import EaziiAlert from "../atoms/Alert/EaziiAlert";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            color={"primary"}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

//#EF9D00
//#EF9D00
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: "120px",/*←footerの高さ*/
        flexGrow: 1,
        width: '100%',
        backgroundColor: "#F2F1F0",
        color: "#EE508"
    },
    selected: {
        color: "#EE508"
    },
    indicator: {
        backgroundColor: "#EE508"
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        height: 20
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
/**
 *
 * @param arrLength
 * @returns {number}
 */
const getItemSize = (arrLength, index) => {
    switch (arrLength) {
        case 2 :
            return 12
        case 1 :
            return 12
        case index + 1 :
            return 12
        default:
            return arrLength === index ? 12 : 6
    }
}
export default function
    ScrollableTabsButtonAuto({groupedItems,}) {
    const theme = useTheme();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const history = useHistory();
    // TODO Alert処理
    const [alertType, setAlertType] = useState('')

    const getPosition = (categoryLength, index) => {
        if (categoryLength.length - 1 === index) {
            return "lastItem"
        } else if (index % 2 === 0) {
            return "rightItem"
        } else {
            return "leftItem"
        }
    }
    const handleClick = (itemId) => {
        history.push(`/items/${itemId}`)
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    useEffect(() => {
        setValue(query.get('id') ? Number(query.get('id')) : 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className={classes.root}>
            <AppBar position="static" color="inherit">
                <Tabs
                    style={{
                        backgroundColor: "white"
                    }}
                    classes={{
                        indicator: classes.indicator
                    }}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {
                        Object.keys(groupedItems ? groupedItems : {}).map((category, index) => (
                            <Tab label={category} {...a11yProps(index)} key={shortid.generate()}/>
                        ))
                    }
                </Tabs>
            </AppBar>
            {alertType && <EaziiAlert type={alertType} title='警告'>
                <span>WIFIに接続されていないようです</span><br/><span>ネットワークの確認をお願いします。</span></EaziiAlert>}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {Object.keys(groupedItems ? groupedItems : {}).map((category, index) =>
                    (
                        <TabPanel value={value} index={index} dir={theme.direction} key={shortid.generate()}>
                            <Grid container
                                  direction="row"
                                  justify="space-around"
                                  alignItems="flex-start"
                            >
                                {groupedItems[category].map((item, index) => (
                                    <Grid
                                        item
                                        xs={getItemSize(groupedItems[category].length, index)}
                                        style={alertType ? {
                                            marginTop: 10,
                                        } : {marginTop: 20}}
                                        key={index}
                                    >
                                        <EaziiItemCard
                                            image={item.imageUrls ? item.imageUrls[0] : item.imageUrl}
                                            itemName={item.itemName}
                                            description={item.description}
                                            price={item.salesPriceWithoutTax}
                                            endDate={item.endAt.toDate()}
                                            handleClick={handleClick}
                                            itemId={item.id}
                                            position={getPosition(groupedItems[category].length, index)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                    ))}
            </SwipeableViews>
        </div>
    );
}
