import React, { useContext, useEffect, useState } from 'react';
import firebase, { db } from "../../utils/Firebase";
import { AuthContext } from "../../context/AuthService";
import { createCustomer, createCustomerCard } from "../../utils/httpRequest";
import { addressType } from "../../utils/types/address";
import { convertPayJpAddressObj } from "../../utils/paymentUtils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { payJpCustomerObj, payjpObj } from "../../atoms/payjp";
import EaziiAlert from "../atoms/Alert/EaziiAlert";
import PaymentEditTemplate from "../templates/PayMentEditTemplate";
import { Button } from "@material-ui/core";
import styled from 'styled-components';


export default function PaymentMethodEdit() {

    const [addressInfo] = useState({});
    const payjp = useRecoilValue(payjpObj)
    const setCustomer = useSetRecoilState(payJpCustomerObj);
    const [eaziiAlert, setEaziiAlert] = useState(<></>);

    const [cardElement, setCardElement] = useState()
    useEffect(() => {
        if (cardElement) {
            //@ts-ignore
            cardElement.mount('#v2-demo')
        }
    }, [cardElement])

    useEffect(() => {
        if (payjp) {
            //@ts-ignore
            let elements = payjp.elements()
            setCardElement(elements.create('card'))
        }
    }, [payjp])

    const user = useContext(AuthContext)
    const onSubmit = (data: addressType) => {
        try {
            //@ts-ignore
            payjp.createToken(cardElement).then(async function (r) {
                if (r.id) {
                    const obj = {
                        token: r.id,
                        email: user ? user.email : "yasuaki.0929@gmail.com",
                    };
                    // 顧客を作成し
                    await createCustomer(obj).then(async customer => {
                        // その情報でカードを作成する
                        await createCustomerCard(convertPayJpAddressObj(data), customer.id, customer.default_card)
                                .then(async r => {
                                    let userRef = db.collection("member").where("userId", "==", user ? user.id : "U9bebb7bfa67b98a00b173a4a4045898c")
                                    userRef.get().then(function (querySnapshot) {
                                        let ref = querySnapshot.docs[0].ref.collection("card")
                                        let defaultCheck = false
                                        ref.get().then((cardSnapshot) => {
                                            if (!cardSnapshot.docs.length) {
                                                defaultCheck = true
                                            }
                                            ref.doc().set({
                                                ...data,
                                                //@ts-ignore
                                                customerId: customer.id,
                                                defaultCheck,
                                                last4: r.last4,
                                                brand: r.brand,
                                                customer: r.customer,
                                                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                                            })
                                        })
                                    })
                                    await setCustomer(r)
                                })
                    }).catch(createCustomerErr => {
                        // TODO エラーコードによってメッセージ変更
                        setEaziiAlert(
                                <EaziiAlert title={createCustomerErr.status} type={'err'}>
                                    {createCustomerErr.response.body}
                                </EaziiAlert>
                        )
                    })
                }
            })


        } catch (e) {
        }
    }


    useEffect(() => {

    }, [addressInfo])

    return (
            <>
                <PaymentEditTemplate
                        onSubmit={onSubmit}
                        eaziiAlert={eaziiAlert}
                />
            </>
    );
}
