import React, { Component, CSSProperties, FC, ReactComponentElement, ReactNode } from "react"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


type IconProps = {
    childComp?: React.ReactNode;
}

export type RadioControlProps = {
    radioValue: string,
    radioLabelText: string,
    icon?: React.ReactNode
}
const RadioControl: FC<RadioControlProps> = ({ radioValue, radioLabelText,icon=<></> }) => {
    return (
            <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    style={{
                        padding: "20px 47px 20px 26px",
                    }}
            >
                <Grid item xs={3}>
                    {icon}
                </Grid>
                <Grid item xs={8}>
                    <Typography color='primary'>{radioLabelText}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <FormControlLabel
                            value={radioValue}
                            control={<Radio />}
                            labelPlacement="start"
                            label={""}
                    />
                </Grid>
            </Grid>
    )
}
export default RadioControl