import React, { useContext, useEffect, useState } from 'react';
import MyHeader from '../molecules/MyHeader';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { AuthContext } from '../../context/AuthService';
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from '../../utils/Firebase';
import { useSetRecoilState } from 'recoil';
import { selectedAddressInfo } from '../../atoms/addressData';
import { useHistory } from 'react-router-dom';
import Progress from '../atoms/Progress/Progress';
import shortid from 'shortid';
import DoneIcon from "@material-ui/icons/Done";
import { theme } from "../../styles/theme";
import { deleteItems } from "../../utils/utils";


const useStyles = makeStyles(theme => ({
    container: {
        padding: '20px 0 20px 22px',
    },
    btnContainer: {
        textAlign: 'center',
        padding: '20px 25px 20px',
    },
}));

export default function Address() {
    const classes = useStyles();
    const user = useContext(AuthContext);
    const [value, loading, error] = useCollection(
            firebase
                    .firestore()
                    .collection(`member/`)
                    .where(
                            'userId',
                            '==',
                            user ? user.id : 'U9bebb7bfa67b98a00b173a4a4045898c'
                    ),
            {}
    );
    const [addressInfo, setAddressInfo] = useState([]);
    const setSelectedAddressInfo = useSetRecoilState(selectedAddressInfo);
    useEffect(() => {
        if (value) {
            value.docs[0].ref
                    .collection('address')
                    .get()
                    .then(querySnapshot => {
                        let newAddressinfo = [];
                        querySnapshot.docs.forEach((el, index) => {
                            newAddressinfo = [...newAddressinfo, { ...el.data(), id: index }];
                        });
                        setAddressInfo(newAddressinfo);
                    })
                    .catch(() => {
                        // TODO
                    });
        }
    }, [value]);

    useEffect(() => {
        deleteItems()
        if (value) {
            value.docs[0].ref
                    .collection('address')
                    .get()
                    .then(querySnapshot => {
                        const checkedIndex = addressInfo.findIndex(el => el.defaultCheck);
                        let i = 0;
                        querySnapshot.forEach(doc => {
                            if (i === checkedIndex) {
                                doc.ref.update({ defaultCheck: true });
                            } else {
                                doc.ref.update({ defaultCheck: false });
                            }
                            i++;
                        });
                    });
        }
    }, [addressInfo, value]);
    // [START handleButton]
    const history = useHistory();
    const handleButton = () => {
        history.push('/address/edit');
    };
    // [END handleButton]
    const changeDoneIcon = id => {
        const newAddressinfo = addressInfo.map(el => {
            if (el.id === id) {
                setSelectedAddressInfo({ ...el, defaultCheck: true });
                return { ...el, defaultCheck: true };
            } else {
                return { ...el, defaultCheck: false };
            }
        });
        setAddressInfo(newAddressinfo);
    };
    return (
            <>
                <MyHeader title={'配送先'} />
                <Paper>
                    {error && <strong>Error: {JSON.stringify(error)}</strong>}
                    {loading && <Progress />}
                    {value && addressInfo && (
                            <>
                                {addressInfo.map(el => {
                                    return (
                                            <div key={shortid.generate()}>
                                                <Grid
                                                        container
                                                        justify='space-between'
                                                        className={classes.container}
                                                        style={
                                                            el.defaultCheck === true
                                                                    ? { border: 'solid 1px #000' }
                                                                    : { border: 'none' }
                                                        }
                                                >
                                                    <Grid item xs={8}
                                                          onClick={() => changeDoneIcon(el.id)}
                                                    >
                                                        <Typography>
                                                            {`${el['family-name-0']}  ${el['first-name-1']}  ${el['family-name-2']}  ${el['first-name-3']}`}
                                                            <br />
                                                            {`${el['postal-code-0']}  ${el['address-level1-1']}  ${el['address-level2-2']}  ${el['address-level3-3']}  ${el['address-level4-4']}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'inline-flex',
                                                              verticalAlign: 'middle'
                                                          }}
                                                    >
                                                        {el.defaultCheck &&
                                                        <DoneIcon fontSize="large" style={{
                                                            fontWeight: 800,
                                                            margin: 'auto auto auto auto',
                                                            color: theme.palette.secondary.main
                                                        }} />
                                                        }
                                                    </Grid>

                                                    <Grid item xs={2}>

                                                        <IconButton
                                                                style={{ marginLeft: '10px' }}
                                                                onClick={() => changeDoneIcon(el.id)}
                                                        >
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                    );
                                })}
                            </>
                    )}
                </Paper>
                <div className={classes.btnContainer}>
                    <Button
                            fullWidth
                            variant='outlined'
                            color='primary'
                            onClick={() => handleButton()}
                    >
                        新規登録
                    </Button>
                </div>
            </>
    );
}
