import React from 'react';
import { useHistory } from 'react-router-dom';
import { getImgByProvideOption, getTitleByProvideOption } from "../../utils/utils";
import OrderCompleteTemplate from "../templates/OrderCompleteTemplate";


export default function OrderComplete() {
    const history = useHistory();
    const onCloseClick = () => {
        history.push('/');
    }

    return (
            <>
                <OrderCompleteTemplate
                        onCloseClick={onCloseClick}
                        imageUrl={getImgByProvideOption()}
                        title={getTitleByProvideOption()}
                />
            </>
    );
}
