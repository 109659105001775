// AuthService.js
import React, { useEffect, useState } from 'react'
import firebase, { db } from "../utils/Firebase";


const liff = window.liff
const AuthContext = React.createContext()


const updateUserInfo = ({ response }) => {
    let userId = response.userId
    let pictureUrl = response.pictureUrl
    let userName = response.displayName
    db.collection("member").where("userId", "==", userId)
        .get().then((memberPostQuery) => {
        if (memberPostQuery.docs.length > 0) {
            const userDoc = memberPostQuery.docs[0];
            userDoc.ref.update({
                userId: userId,
                userName: userName ? userName : "○○様",
                profileUrl: pictureUrl,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } else {
            db.collection("member").add({
                userId: userId,
                userName: userName,
                profileUrl: pictureUrl,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(() => {
                // TODO
            }).catch(() => {
                // TODO
            });
        }
    }).catch(() => {
        //TODO
    });
}
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    useEffect(() => {
        liff.init({ liffId: process.env.REACT_APP_LIFF_ID }).then(() => {
            if (liff.isLoggedIn()) {
                liff.getProfile().then(
                    (response) => {
                        updateUserInfo(response)
                        setUser({ id: response.userId, name: response.displayName, email: "yasuaki.0929@gmail.com" })
                    }
                )
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    )
}
export {
    AuthContext,

}
export default AuthProvider
