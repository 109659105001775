import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        textAlign: "center",
    }
})

interface AlertDialogProps {
    open:boolean;
    onClose:(()=>void);
    title:string;
    message:string;
}

export default function AlertDialog({ open, onClose, title, message }:AlertDialogProps):JSX.Element {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent >
                <DialogContentText >
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <Button  onClick={onClose} variant="outlined" color="primary">
                    × 閉じる
                </Button>
            </DialogActions>
        </Dialog>
    );
}