import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MyHeader from "../molecules/MyHeader";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { millisToFormatString } from "../../utils/utils";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as Fork } from '../assets/icons/fork.svg'
import { ReactComponent as Bag } from '../assets/icons/bag.svg'
import { ReactComponent as Delivery } from '../assets/icons/delivery.svg'
import BoxTypographyBottom from "../../component/atoms/BoxTypographyBottom";
import BottomClose from "../molecules/BottomClose";
import OrderedButtons from "../molecules/OrderedButtons";


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        paddingBottom: "120px" /*←footerの高さ*/,
    },
    image: {
        width: 100,
        height: 100,
    },
    img: {
        margin: "auto",
        display: "block",
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));
export default function Order() {
    const classes = useStyles();
    const location = useLocation();
    const [howToUse, setHowToUse] = useState(false);

    /** ご利用方法の開閉 */
    const repurchase = () => {
        setHowToUse(!howToUse);
    };

    const items = location.state.data.items
    const datas = Object.keys(items)
    /** 商品詳細を取り出す */
    const itemData = () => {
        for (let key of datas) {
            return items[key]
        }
    }

    return (
        <>
            <MyHeader title={"注文履歴詳細"} style={{fontSize: 20}}/>
            <BoxTypographyBottom
                title={millisToFormatString(location.state.data.createdAt.seconds * 1000)}
            />
            <div className={classes.root}>
                <Divider/>
                <div style={{width: "100%", margin: "30px auto"}}>

                    <Grid container direction='row'
                          style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "90%",
                          }}
                    >
                        <Grid item xs={4}>
                            <img className={classes.image} src={itemData().imageUrls[0]} alt={'img'}/>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography align={"left"} color='primary'
                                        style={{
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            marginBottom: "10px",
                                        }}
                            >
                                {itemData().itemName}
                            </Typography>
                            <Typography align={"left"} style={{fontSize: 13}} color='primary'>
                                {itemData().description}
                            </Typography>
                            <div>
                                <Typography align={"left"} style={{fontSize: 18}} color='primary'>
                                    ¥{itemData().salesPriceWithoutTax}
                                    <span style={{fontSize: 13, marginLeft: 10, color: '#9B9B9B'}}>税込</span>
                                </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={3}>
                            <OrderedButtons repurchase={repurchase}/>
                        </Grid>
                    </Grid>
                </div>
                <Divider/>
                <Grid item xs={12} style={{padding: "16px", display: "flex"}}>
                    <Grid item xs={6}>

                        <Typography
                            align={"left"}
                            style={{fontSize: 13}}
                            color='primary'
                        >
                            配送料
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            align={"right"}
                            color='primary'
                            style={{fontSize: 18, marginRight: "10px",}}
                        >
                            ￥15,000
                        </Typography>
                    </Grid>
                </Grid>
                <Grid spacing={1} style={{padding: "16px"}}>
                    <Grid item xs={12} style={{display: "flex"}}>
                        <Grid item xs={6}>
                            <Typography
                                align={"left"}
                                color='primary'
                                style={{
                                    fontSize: 13,

                                }}
                            >
                                お支払金額
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography
                                align={"right"}
                                color='primary'
                                style={{
                                    fontSize: 26,
                                    fontWeight: "bold",
                                }}
                            >
                                {new Intl.NumberFormat("ja-JP", {
                                    style: "currency",
                                    currency: "JPY",
                                }).format(
                                    itemData().sumPrice + 15000
                                        ? itemData().sumPrice + 15000
                                        : 55 + 15000
                                )}

                                <Typography
                                    color='primary'
                                    component={"span"}
                                    style={{
                                        fontSize: 15,
                                        fontWeight: "bold",
                                        marginRight: "10px",
                                    }}
                                >
                                    税込
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    howToUse && (
                        <>
                            <BoxTypographyBottom title={"支払い方法"}/>
                            <div style={{height: 65}}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography
                                        color='primary' style={{fontSize: 16, marginTop: 25, paddingLeft: 15}}
                                    >
                                        請求書払い
                                    </Typography>
                                    <IconButton style={{marginTop: 15}}>
                                        <ArrowForwardIosIcon fontSize={"small"}/>
                                    </IconButton>
                                </div>
                            </div>

                            <>
                                <BoxTypographyBottom title={"ご利用方法"}/>
                                <form>
                                    <RadioGroup name="orderMethod">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-evenly"
                                            alignItems="right"
                                            style={{
                                                padding: "20px 47px 20px 26px",
                                            }}
                                        >
                                            <Grid item xs={3}>
                                                <Fork style={{height: '20px', width: '20px', fill: '#442D29'}}/>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography color='primary'>店内飲食</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <FormControlLabel
                                                    value="eatIn"
                                                    control={<Radio/>}
                                                    labelPlacement="start"
                                                    label={""}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-evenly"
                                            alignItems="right"
                                            style={{
                                                padding: "20px 47px 20px 26px",
                                            }}
                                        >
                                            <Grid item xs={3}>
                                                <Bag style={{height: '20px', width: '20px', fill: '#442D29'}}/>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography color='primary'>お持ち帰り</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <FormControlLabel
                                                    value="takeout"
                                                    control={<Radio/>}
                                                    labelPlacement="start"
                                                    label={""}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-evenly"
                                            alignItems="right"
                                            style={{
                                                padding: "20px 47px 20px 26px",
                                            }}
                                        >
                                            <Grid item xs={3}>
                                                <Delivery style={{height: '20px', width: '20px', fill: '#442D29'}}/>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography color='primary'>配達</Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <FormControlLabel
                                                    value="delivery"
                                                    control={<Radio/>}
                                                    labelPlacement="start"
                                                    label={""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </form>
                            </>

                            <BoxTypographyBottom title={"配送先"}/>
                            <Grid style={{paddingLeft: 15, paddingTop: 20}}>
                                <Typography color='primary' style={{fontSize: 16}}>○○店長様</Typography>
                                <Typography color='primary' style={{fontSize: 16}}>東京都港区●●1-1-1</Typography>
                                <Typography color='primary' style={{fontSize: 16}}>○○港南店</Typography>
                            </Grid>
                        </>
                    )
                }
            </div>
            <BottomClose isDialog={true}/>
        </>
    )

}
