import React, { useContext, useEffect, useState } from 'react';
import TabPanel from "./TabPanel.jsx";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "../../utils/Firebase.js";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Progress from "../atoms/Progress/Progress";
import AlertDialog from "../molecules/AlertDialog";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { liffDataState, purchaseDataState } from "../../atoms/PurchaseData";
import { selectedAddressInfo } from "../../atoms/addressData"
import BottomNavigationGroup from "../molecules/BottomNavigationGroup";
import { useForm } from "react-hook-form";
import AuthContext from "../../context/AuthService"
import { payJpCustomerObj } from "../../atoms/payjp";
import { selectedCardInfo } from "../../atoms/cardInfo";
import EaziiLogoIcon from "../assets/icons/EaziiLogoIcon";


const liff = window.liff

export default function Main() {

    // hooks
    const [purchaseData, setPurchaseData] = useState([]);
    const eetRecoilPurchaseData = useSetRecoilState(purchaseDataState);
    // const _purchasedData = useRecoilValue(purchaseDataState);
    const _purchasedData = useRecoilValue(purchaseDataState);
    const setCustomerInfo = useSetRecoilState(payJpCustomerObj)
    const { register, handleSubmit } = useForm();
    const location = useLocation();
    const user = useContext(AuthContext)
    const [recoilAddressInfo, setSelectedAddressInfo] = useRecoilState(selectedAddressInfo)
    const setLiffData = useSetRecoilState(liffDataState);
    const setSelectedCardInfo = useSetRecoilState(selectedCardInfo)
    const [dialogOpen, setDialogOpen] = useState(false);

    const onSubmit = () => {
        let AmountTotal = 0;
        for (const property in _purchasedData.items) {
            AmountTotal += _purchasedData.items[property].salesAmount;
        }
        if (!AmountTotal) {
            handleDialogOpen(true);
        } else {
            history.push(`/confirm`)
        }
    }

    // ダイアログのON/OFF
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const dialogClose = () => {
        setDialogOpen(false);
    };

    const [groupedItems, setGroupedItems] = useState();
    const [value1, loading, error] = useCollection(
            firebase.firestore().collection("items").where("userId", "==", process.env.REACT_APP_USER_ID).where("createdAt", ">=", new Date("2020/05/01")).orderBy("createdAt", "desc"),
            {
                snapshotListenOptions: { includeMetadataChanges: true },
            }
    );

    // 顧客情報
    const [categories, categoryLoading] = useCollection(
            firebase.firestore().collection("category").orderBy("createdAt", "desc"),
            {
                snapshotListenOptions: { includeMetadataChanges: true },
            }
    );
    const [currentUser] = useCollection(
            firebase.firestore().collection("member").where("userId", "==", user ? user.id : "U9bebb7bfa67b98a00b173a4a4045898c"),
            {}
    )


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const history = useHistory();
    useEffect(() => {
        if (query.get("page") === "orders") {
            history.push("/orders")
        }

    }, [])
    const groupCategory = (docs) => {
        return docs.reduce((result, current) => {
            if (current.data().category) {
                if (!result[current.data().category.title]) result[current.data().category.title] = []
                result[current.data().category.title].push({
                    ...current.data(),
                    id: current.id
                })
            }
            return result
        }, {})
    }

    let { tableId } = useParams();
    // [START useEffect]
    useEffect(() => {
        if (value1 && categories) {
            let groupedItems = groupCategory(value1.docs)
            setGroupedItems(groupedItems)
            let docs = value1.docs;
            let _categorizedItems = {};

            for (let i = 0; i < docs.length; i++) {
                let data = docs[i].data();
                let prevCategory = "";
                if (i > 0) {
                    prevCategory = docs[i - 1].data().category;
                } else {
                    prevCategory = data.category;
                }
                if (prevCategory === data.category) {
                    if (_categorizedItems[prevCategory]) {
                        _categorizedItems[prevCategory].push(data)
                    } else {
                        _categorizedItems[prevCategory] = [data]
                    }
                }
            }


            if (!Object.keys(purchaseData).length) {
                let _data = {}
                _data["items"] = {}
                if (!Object.keys(_purchasedData.items).length) {
                    for (let _doc of value1.docs) {
                        _data["items"][_doc.id] = { salesAmount: 0 }
                        for (let item in _doc.data()) {
                            _data["items"][_doc.id][item] = _doc.data()[item]
                        }
                    }
                    _data['sumPrice'] = 0
                    _data['totalSalesAmount'] = 0
                    _data['tableId'] = tableId ? tableId : 0
                    eetRecoilPurchaseData(_data)
                    setPurchaseData(
                            value1.docs.map(item => {
                                return {
                                    itemId: item.id,
                                    sumPrice: 0,
                                    description: item.data().description,
                                    title: item.data().itemName,
                                    price: item.data().salesPriceWithoutTax,
                                    imageUrl: item.data().imageUrl ? item.data().imageUrl : item.data().imageUrls[0],
                                    tableId: tableId ? tableId : 0
                                };
                            })
                    )
                }
            }
        }

        if (!recoilAddressInfo && currentUser) {
            currentUser.forEach((querySnapshot) => {
                querySnapshot.ref.collection("address").get().then((docs) => {
                    docs.forEach((doc) => {
                        if (doc.data().defaultCheck) {
                            setSelectedAddressInfo(doc.data())
                        }
                    })
                })
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value1, categories])
    // [END EFFECT]

    // 顧客情報をセット
    useEffect(() => {
        if (currentUser) {
            currentUser.docs[0].ref.collection("card")
                    .where('defaultCheck', '==', true)
                    .get().then(querySnapshot => {
                setCustomerInfo(querySnapshot.docs[0].data())
                setSelectedCardInfo(querySnapshot.docs[0].data())
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])
    // [END useEffect]
    const getIcon = () =>{
        switch (process.env.REACT_APP_USER_ID){
            case "":
                return(
                        <div style={{
                            display: 'block',
                            margin: "0 auto"
                        }}>
                            <EaziiLogoIcon />
                        </div>
                )
            case "r6vOZDm2jcfTPZXMRhrBq7tOoW53":
                return (<></>)

        }
    }
    return (
            <>
                {getIcon()}
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && categoryLoading && <Progress />}
                {value1 && categories && (
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TabPanel
                                        register={register}
                                        groupedItems={groupedItems}
                                        purchaseData={purchaseData}
                                        setPurchaseData={setPurchaseData}
                                />
                                <BottomNavigationGroup />
                            </form>
                            <AlertDialog
                                    open={dialogOpen}
                                    onClose={dialogClose}
                                    title="EaziiAlert!!"
                                    message="カートが空です！"
                            />
                        </>
                )}
            </>
    )
}

