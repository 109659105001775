import React, { FC, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export type DeleteAlertProps = {
    open: boolean,
    handleDeleteNoClick: (() => void),
    handleDeleteYesClick: (() => void),
    message: string,
    title: string,
}
const useStyles = makeStyles({
    title: {
        textAlign: "center",
    }
})
const DeleteAlert: FC<DeleteAlertProps> = ({open, handleDeleteNoClick, handleDeleteYesClick, message, title}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={() => handleDeleteYesClick()} variant="outlined" color="primary">
                    はい
                </Button>
                <Button onClick={handleDeleteNoClick} variant="outlined" color="primary">
                    いいえ
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAlert