import { createMuiTheme } from '@material-ui/core/styles'

// Material UIのテーマ上書き
export const theme = createMuiTheme({
    // カラーパレット追加
    palette: {
        type: 'light',
        primary: {
            '50': '#e9e6e5',
            '100': '#c7c0bf',
            '200': '#a29694',
            '300': '#7c6c69',
            '400': '#604d49',
            '500': '#442d29',
            '600': '#3e2824',
            '700': '#35221f',
            '800': '#2d1c19',
            '900': '#1f110f',
            'A100': '#ff6e61',
            'A200': '#ff3f2e',
            'A400': '#fa1500',
            'A700': '#e01300',
            contrastText:"#442d29",
            'contrastDefaultColor': 'light',
        },
        secondary: {
            '50': '#fff1e8',
            '100': '#ffddc5',
            '200': '#ffc69f',
            '300': '#ffaf78',
            '400': '#ff9d5b',
            '500': '#ff8c3e',
            '600': '#ff8438',
            '700': '#ff7930',
            '800': '#ff6f28',
            '900': '#ff5c1b',
            'A100': '#ffffff',
            'A200': '#ffffff',
            'A400': '#ffd9cc',
            'A700': '#ffc6b3',
            'contrastDefaultColor': 'light',
            'main': "#ff8c3e",
        },
        text:{
            primary:"#442d29",
            secondary:"#ff8c3e",
            disabled: "#442d29",
            hint:"#442d29"
        },
        action:{
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground:
                "rgba(0, 0, 0, 0.12)",
            disabledOpacity: "0.38",
        }
    },
    text:{
        primary:"#442d29",
        secondary: "#442d29"
    },
    typography:{
        color:"#442d29"
    }
});
