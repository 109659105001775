import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MyHeader from "../molecules/MyHeader";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { lightGray } from "../../utils/utils";
import Box from "@material-ui/core/Box";
import BottomRegister from "../molecules/BottomRegister";
import { useForm } from "react-hook-form";
import shortid from "shortid"
import firebase, { db } from "../../utils/Firebase";
import { AuthContext } from "../../context/AuthService";
import EaziiAlert from "../atoms/Alert/EaziiAlert"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: "120px",/*←footerの高さ*/
    },
    textField: {
        backgroundColor: "white"
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    noPadding: {
        padding: 0
    },
    notchedOutline: {
        borderWidth: "0",
    },
    breakBox: {
        backgroundColor: lightGray,
        width: "100%",
        height: "20px"
    }
}));
const data1 = [
    { name: "姓(全角)", placeholder: "例)飯地", autoComplete: "family-name" },
    { name: "名(全角)", placeholder: "例)太郎", autoComplete: "first-name" },
    { name: "姓カナ(全角)", placeholder: "例)イイジ", autoComplete: "family-name" },
    { name: "名カナ(全角)", placeholder: "例)タロウ", autoComplete: "first-name" },
]
const data2 = [
    { name: "郵便番号(数字)", placeholder: "例)1234567", autoComplete: "postal-code" },
    { name: "都道府県", placeholder: "選択してください", autoComplete: "address-level1" },
    { name: "市区町村", placeholder: "例)東京都文京区", autoComplete: "address-level2" },
    { name: "番地", placeholder: "例)大塚1-1-1", autoComplete: "address-level3" },
    { name: "建物名(任意)", placeholder: "例)飯地ビル101", autoComplete: "address-level4" },
]
export default function AddressForm() {

    const { register, handleSubmit, errors } = useForm();
    const [addressInfo] = useState();

    const user = useContext(AuthContext)
    const onSubmit = data => {
        try {

            let userRef = db.collection("member").where("userId", "==", user ? user.id : "U9bebb7bfa67b98a00b173a4a4045898c")
            userRef.get().then(function (querySnapshot) {
                let ref = querySnapshot.docs[0].ref.collection("address")
                ref.get().then((querySnapshot) => {
                    if (querySnapshot.docs.length) {
                        ref.doc().set({
                            ...data,
                            defaultCheck: false,
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                    } else {
                        ref.doc().set({
                            ...data,
                            defaultCheck: true,
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        })
                    }
                })
            })

        } catch (e) {
        }
    }


    const MyData = ({ data, register }) => {
        return (
            <>
                {data.map((d1, index) => (
                    <>

                        <Grid item xs={4}
                            style={{
                                margin: "15px 0px",
                            }}
                        >
                            <Typography style={{
                                fontWeight: 800
                            }}>
                                {d1.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <InputBase
                                key={shortid.generate()}
                                placeholder={d1.placeholder}
                                autoComplete={d1.autoComplete}
                                name={`${d1.autoComplete}-${index}`}
                                inputProps={{ 'aria-label': 'naked', ref: register({ required: true }) }}
                            />
                        </Grid>
                        <Box style={{
                            backgroundColor: lightGray,
                            height: "1px",
                            width: "100%"

                        }}
                        />
                    </>
                ))}

            </>
        )
    }
    const classes = useStyles();

    useEffect(() => {

    }, [addressInfo])

    return (
        <React.Fragment>
            <MyHeader title={"配送先の登録"} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.root}>

                    <Paper className={classes.paper}>
                        {Object.keys(errors).length ? <EaziiAlert severity="warning" title="全ての項目を入力してください" /> : ""}
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            spacing={1}
                            style={{}}
                        >

                            <MyData data={data1} register={register} />
                            <Box className={classes.breakBox} />
                            <MyData data={data2} register={register} />


                        </Grid>

                    </Paper>
                </div>
                <BottomRegister addressInfo={addressInfo} />

            </form>
        </React.Fragment>
    );
}

