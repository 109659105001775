import React, { useEffect, useState, FC } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { purchaseDataState } from '../../atoms/PurchaseData';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { PurchaseData } from '../../Types';

const useStyles = makeStyles({
  root: {
    height: 50,
    width: 50,
    touchAction: 'manipulation',
  },
  label: {
    fontSize: '1.8rem',
  },
  input: {
    height: '50px',
    width: '50px',
  },
  iconButton: {
    padding: '6px',
  },
  grouped: {
    margin: '3px',
  },
});

const defaultProps = {
  bgcolor: 'background.paper',
  borderColor: '#E0E0E0',
  height: '60',
  m: 1,
  border: 1,
};

type InputAmountProps = {
  itemId: string;
  setDeleteAlertId?: React.Dispatch<React.SetStateAction<string>>;
};

const InputAmount: FC<InputAmountProps> = ({ itemId, setDeleteAlertId = null }) => {
  const [purchaseData, setPurchaseData] = useRecoilState<PurchaseData>(
    purchaseDataState
  );
  const [amount, setAmount] = useState(purchaseData.items[itemId].salesAmount);
  useEffect(() => {}, [purchaseData]);

  const handleButton = (isIncrement: boolean) => {
    if (isIncrement) {
      setAmount(amount + 1);
      let _purchaseData = JSON.parse(JSON.stringify(purchaseData));
      _purchaseData.sumPrice += +_purchaseData['items'][itemId].taxIncludePrice;
      _purchaseData['items'][itemId].salesAmount += 1;
      _purchaseData.totalSalesAmount += 1;
      setPurchaseData(_purchaseData);
    } else if (amount > 0) {
      setAmount(amount - 1);
      let _purchaseData = JSON.parse(JSON.stringify(purchaseData));
      if (
        setDeleteAlertId &&
        _purchaseData['items'][itemId].salesAmount === 1
      ) {
        console.log('deleteAlert');
        setDeleteAlertId(itemId);
        return;
      }

      _purchaseData.sumPrice -= +_purchaseData['items'][itemId].taxIncludePrice;

      _purchaseData['items'][itemId].salesAmount -= 1;
      _purchaseData.totalSalesAmount -= 1;
      setPurchaseData(_purchaseData);
    }
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <ButtonGroup aria-label='secondary outlined button group'>
        <Box borderRadius={47} {...defaultProps} className={classes.grouped}>
          <IconButton
            aria-label='decrement'
            className={classes.iconButton}
            onClick={() => handleButton(false)}
          >
            <RemoveCircleIcon color={!amount ? 'disabled' : 'secondary'} />
          </IconButton>
          <InputBase
            onChange={(e) => {
              let number = +e.target.value;
              if (number) {
                setAmount(number);
                let _purchaseData = JSON.parse(JSON.stringify(purchaseData));
                _purchaseData.sumPrice =
                  number * _purchaseData.items[itemId].taxIncludePrice * 1.08;
                _purchaseData.items[itemId].salesAmount += Number;
                _purchaseData.totalSalesAmount += number;
                setPurchaseData(_purchaseData);
              }
            }}
            type='number'
            disabled
            inputProps={{
              min: '0',
              style: {
                height: '1.125rem',
                width: '1.8125rem',
                textAlign: 'center',
              },
            }}
            value={amount}
          />

          <IconButton
            aria-label='add'
            onClick={() => handleButton(true)}
            className={classes.iconButton}
          >
            <AddCircleIcon color={'secondary'} />
          </IconButton>
        </Box>
      </ButtonGroup>
    </React.Fragment>
  );
};

export default InputAmount;
