import React, { FC } from 'react'
import MyHeader from "../molecules/MyHeader";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PayJp from "../atoms/Payjp/PayJp";
import BottomRegister from "../molecules/BottomRegister";
import { addressType } from "../../utils/types/address";
import { lightGray } from "../../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import AddressFormInputs from '../molecules/AddressPlaceholder';
import { useForm } from 'react-hook-form';
import EaziiAlert from "../atoms/Alert/EaziiAlert"

type PayMentEditTemplateType = {
    onSubmit: ((data: addressType) => void),
    eaziiAlert: JSX.Element,
}

const PaymentEditTemplate: FC<PayMentEditTemplateType> =
    ({
        onSubmit,
        eaziiAlert,
    }) => {
        const classes = useStyles();
        const { register, handleSubmit, errors } = useForm<addressType>();

        return (
            <React.Fragment>
                <MyHeader title={"お支払い方法編集"} />
                {eaziiAlert}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            {Object.keys(errors).length ? <EaziiAlert type="warning" title="全ての項目を入力してください" /> : ""}
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                spacing={1}
                                style={{}}
                            >
                                <AddressFormInputs data={data1} register={register} />
                                <Box className={classes.breakBox} />
                                <AddressFormInputs data={data2} register={register} />
                                <Box className={classes.breakBox} />
                            </Grid>
                            <PayJp />
                            <input id="token" ref={register} hidden={true} name="token" />
                        </Paper>
                    </div>
                    <BottomRegister />
                </form>
            </React.Fragment>
        )
    }

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: "120px",/*←footerの高さ*/
    },
    textField: {
        backgroundColor: "white"
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    noPadding: {
        padding: 0
    },
    notchedOutline: {
        borderWidth: "0",
    },
    breakBox: {
        backgroundColor: lightGray,
        width: "100%",
        height: "20px"
    }
}));


const data1 = [
    { title: "姓(全角)", placeholder: "例)飯地", autoComplete: "family-name", name: "family_name" },
    { title: "名(全角)", placeholder: "例)太郎", autoComplete: "first-name", name: "first_name" },
    { title: "姓カナ(全角)", placeholder: "例)イイジ", autoComplete: "family-name", name: "family_name_kana" },
    { title: "名カナ(全角)", placeholder: "例)タロウ", autoComplete: "first-name", name: "first_name_kana" },
]
const data2 = [
    {
        title: "郵便番号(数字)",
        placeholder: "例)1234567",
        autoComplete: "postal-code",
        name: "postal_code"
    },
    {
        title: "都道府県",
        placeholder: "選択してください",
        autoComplete: "address-level1",
        name: "address_level1"
    },
    {
        title: "市区町村",
        placeholder: "例)東京都文京区",
        autoComplete: "address-level2",
        name: "address_level2"
    },
    {
        title: "番地",
        placeholder: "例)大塚1-1-1",
        autoComplete: "address-level3",
        name: "address_level3"
    },
    {
        title: "建物名(任意)",
        placeholder: "例)飯地ビル101",
        autoComplete: "address-level4",
        name: "address_level4"
    },
]
export default PaymentEditTemplate