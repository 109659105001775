import React, { useEffect, useRef } from "react";
import ImageGallery from 'react-image-gallery';

const MyImageGallery=({images})=> {
    // hooks
    // end hooks
    const refImg = useRef(null)


    // [START useEffect]A

    useEffect(() => {
    }, [])

    // [END useEffect]
    const _onImageClick=(event)=> {
    }

    return <ImageGallery
        ref={refImg}
        showBullets={true}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        onClick={e => _onImageClick(e)}
        items={images}/>;
}

export default MyImageGallery;
