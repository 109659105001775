import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputAmount from "../../atoms/InputAmount";
import {dateToFormatString} from "../../../utils/utils"

const useStyles = makeStyles({
    leftItem:{
      height:"305px",
      marginLeft:"10px",
      marginRight:"5px"
    },
    rightItem:{
      height:"305px",
      marginLeft:"5px"
    },
    lastItem: {
      height:"305px",
      marginLeft:"7px",
      marginRight:"7px",
      marginBottom:"10px"
    },
    media: {
        height: 130,
    },
    itemName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize:"16px",
        fontWeight:"bold"
    },
    desc:{
      fontSize:"13px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    price:{
      fontSize:"18px",
      fontWeight:"bold"
    },
    endDate: {
      fontSize:"13px",
      fontWeight:"bold"
    },
    cardContent: {
      paddingBottom:"0px"
    },
    buttonWrapper: {
      padding:"0px",
      display:"flex",
      width:"100%",
      justifyContent:"flex-end"
    },
});

/**
 * コンポーネント引数
 */
type EaziiItemCardType = {
    image: string,
    itemName: string,
    description: string,
    itemId: string,
    price: number,
    handleClick: (itemId: string) => void
    endDate: Date,
    position:"leftItem" | "rightItem" | "lastItem";
}
const EaziiItemCard: FC<EaziiItemCardType> = (
    {
        image,
        itemName,
        description,
        itemId,
        price,
        handleClick,
        endDate,
        position
    }) => {
    const classes = useStyles();
    return (
        <Card className={classes[position]}>
            <CardActionArea onClick={()=>handleClick(itemId)}>
              <CardMedia
                  className={classes.media}
                  image={image}
                  title={itemName}
              />
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant="body2"
                            component="p"
                            className={classes.itemName}
                        >
                            {itemName}
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            component="p"
                            className={classes.desc}
                        >
                            {description}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textPrimary"
                            component="p"
                            className={classes.price}
                        >
                            ￥{price}
                            <span style={{fontSize:"13px",color:"#9B9B9B"}}> 税抜き</span>
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            className={classes.endDate}
                        >
                            {dateToFormatString(endDate,'%M%月%DD%日 %HH%:%mm%')}まで
                        </Typography>
                    </CardContent>
            </CardActionArea>
            <CardActions className={classes.buttonWrapper}>
              <Typography style={{fontSize:"0.8125rem"}}
                          align={"left"}
              >
                数量
              </Typography>
              <InputAmount
                       itemId={itemId}
              />
            </CardActions>
        </Card>
    );
}
export default EaziiItemCard;
