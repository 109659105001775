import { atom } from 'recoil';


export const payjpObj = atom({
    key: 'payjpObj',
    default: window.Payjp(process.env.REACT_APP_PAYJP_ID),
})

export const payJpCustomerObj = atom({
    key:'payJpCustomerObj',
    default:{}
})
