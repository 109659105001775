import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Progress from "../atoms/Progress/Progress";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "../../utils/Firebase";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { millisToFormatString, toYen } from "../../utils/utils";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { removeTrailingSlash } from "../../utils/utils.js";
import MyHeader from "../molecules/MyHeader";
import CardMedia from '@material-ui/core/CardMedia';
import { AuthContext } from "../../context/AuthService";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    text: {
        padding: '28px 8px'
    },
    image: {
        width: 128,
        height: 128,
    },
    itemName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: '18px',
        fontWeight: 'bold'
    }
}));

const aaa = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    text: {
        width: '100%',
        padding: '28px 8px'
    }

}));


export default function SimpleList() {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const aClass = aaa();
    const handleClick = (id, data) => {
        history.push({
            pathname: `${removeTrailingSlash(location.pathname)}/${id}`,
            state: {
                data: data
            }
        });
    }
    const user = useContext(AuthContext)

    const [value1, loading, error] = useCollection(
        firebase.firestore().collection("orders")
            .where("userId", "==", user ? user.id : "U9bebb7bfa67b98a00b173a4a4045898c")
        , {snapshotListenOptions: {includeMetadataChanges: true}}
    );

    return (
        <>
            <MyHeader title={"注文履歴一覧"}/>
            <div className={classes.root}>
                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <Progress/>}
                {value1 && (
                    <>
                        {value1.docs.map(doc => {
                            const keys = Object.keys(doc.data().items)
                            const firstItem = doc.data().items[keys[0]]
                            return (
                                <>
                                    <Button fullwidth
                                            className={aClass.text}
                                            onClick={() => handleClick(doc.id, doc.data())}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            style={{height: '100%'}}
                                        >

                                            <Grid item xs={12}
                                                  style={{display: 'flex', justifyContent: 'space-between'}}>
                                                <Grid item xs={5}>
                                                    <CardMedia className={classes.image}
                                                               image={firstItem ? firstItem.imageUrls[0] : null}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography color='primary' align='left'
                                                                className={classes.itemName}>
                                                        {firstItem ? (keys.length >= 1 ? `${firstItem.itemName} 他` : firstItem.itemName) : null}
                                                    </Typography>
                                                    <Typography color='primary' align='left'
                                                                style={{fontSize: '13px', marginTop: 10}}>
                                                        {millisToFormatString(doc.data().createdAt.toMillis())}
                                                    </Typography>
                                                    <Typography align='left' style={{
                                                        fontSize: '18px',
                                                        fontWeight: 'bold',
                                                        marginTop: 10
                                                    }}>
                                                        {toYen(doc.data().sumPrice, "")}
                                                        <span style={{
                                                            fontSize: "13px",
                                                            color: '#9B9B9B',
                                                            marginLeft: 10
                                                        }}>
                                                        税込
                                                    </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
                                                    <ArrowForwardIosIcon fontSize={"small"}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Button>
                                    <Divider/>
                                </>
                            )
                        })}
                    </>
                )}

            </div>

        </>
    );
}
