import { createCustomerType } from "./types/httpRequest";
import { payjpAddressType } from "./types/address";

/**
 *
 * @param {createCustomerType} data
 * @returns {Promise<any>}
 */
const createCustomer = (data: createCustomerType) => {
    const url = 'https://us-central1-linebuy.cloudfunctions.net/payment/customer/create';
    return _fetchData(url, "POST", data)
}

/**
 * createCharge
 * @param {number} amount
 * @param customer
 * @param card
 * @param capture
 * @param description
 * @param metadata
 * @returns {Promise<any>}
 */
const createCharge = (amount: number, customer: string, card = "", capture = false, description = '', metadata = {}) => {
    const url = 'https://us-central1-linebuy.cloudfunctions.net/payment/charge/create';
    return _fetchData(url, "POST", {amount, customer, card, capture, description, metadata})
}

/**
 * captureCharge
 * @param {string} id
 * @returns {Promise<any>}
 */
const captureCharge = (id: string) => {
    const url = 'https://us-central1-linebuy.cloudfunctions.net/payment/charge/capture'
    return _fetchData(url, "POST", {id})
}
/*
 *
 * @param {createCustomerCardType} data
 * @param {string} customerId
 * @param {string} cardId
 * @returns {Promise<any>}
 */
const createCustomerCard = (data: payjpAddressType, customerId: string, cardId: string) => {
    const url = `https://us-central1-linebuy.cloudfunctions.net/payment/customers/${customerId}/cards/${cardId}`;
    return _fetchData(url, "POST", data)
}


/**
 * _fetchData
 * fetchの基本的な情報を記述して使い回す
 * @date 2020-07-13
 * @param {string/} url
 * @param {string} method
 * @param {Object} data={}
 * @returns {Promise}
 */
const _fetchData = (url: string, method: string, data = {}) => {
    let param: RequestInit
    param = {
        method: method,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        redirect: "follow",
        referrer: "no-referrer",
    }
    if (Object.keys(data).length) {
        param["body"] = JSON.stringify(data)
    }
    return fetch(url, {
        ...param
    })
        .then(response => response.json());
}

export {
    createCustomer,
    createCustomerCard,
    createCharge,
    captureCharge
}
