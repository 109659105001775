import React, { FC } from 'react'
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const defaultProps = {
    bgcolor: '#F2F1F0',
    borderColor: '#F2F1F0',
    height: "60",
    m: 1,
    border: 1,
};
export type DeleteButtonProps = {
    handleDeleteClick: (() => void)
}
const DeleteButton: FC<DeleteButtonProps> = ({handleDeleteClick}) => {
    return (
        <Box borderRadius={47} {...defaultProps}>
            <Button
                style={{borderRadius: 47}}
                onClick={handleDeleteClick}
            >
                <Typography color={"primary"}
                            align={"center"}
                            style={{
                                height: "2.125rem",
                                width: "6.3125rem",
                                lineHeight: "2.125rem"
                            }}
                >
                    削除
                </Typography>
            </Button>
        </Box>
    )
}
export default DeleteButton;