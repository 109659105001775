import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useRecoilValue } from "recoil";
import { purchaseDataState } from "../../atoms/PurchaseData";
import { toYen } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    text: {
        padding: theme.spacing(2, 2, 0),
    },
    paper: {
        paddingBottom: 50,
    },
    list: {
        marginBottom: theme.spacing(2),
    },
    subheader: {
        backgroundColor: theme.palette.background.paper,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor:"#442D29"
    },
    grow: {
        flexGrow: 1,
    },
    dialogPaper: {
        backgroundColor: "#F2F2F2"
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));




export default function BottomNavigationGroup():JSX.Element {

    //hooks
    const _purchasedData = useRecoilValue(purchaseDataState);


    /*
     TODO:購入処理
     DB連携等
     */
    const classes = useStyles();
    useEffect(() => {

    }, [_purchasedData]);
    return (
        <React.Fragment>

            <AppBar position="fixed" color="inherit" className={classes.appBar}>
                <Grid container alignItems="flex-end" justify="space-between" spacing={3}>
                    <Grid item style={{
                        padding: "21px",
                    }} xs={5}>
                        <Typography style={{fontSize:"13px", color:"#fff"}} variant={"caption"}>
                            合計（税込み）
                        </Typography>
                        <Typography style={{fontSize:"26px", color:"#fff", fontWeight:"bold"}}>
                            {toYen(_purchasedData.sumPrice ? _purchasedData.sumPrice : 0)}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} style={{marginRight:"10px"}}>
                        <Button
                            type={"submit"}
                            style={{
                                width: "100%",
                                height:"50px",
                                margin: "13px auto 15px",
                                backgroundColor: "#FF8C3E",
                                color: "white",
                                fontSize:"17px",
                                fontWeight:"bold",
                            }}>
                            レジに進む
                        </Button>
                    </Grid>
                </Grid>
            </AppBar>
        </React.Fragment>
    );
}
