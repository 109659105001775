import React from 'react'
import RadioControl from "../molecules/Radio/RadioControl";
import { ReactComponent as DeliveryIcon } from "../assets/icons/delivery.svg";
import { ReactComponent as TakeoutIcon } from "../assets/icons/bag.svg";
import { ReactComponent as EatInIcon } from "../assets/icons/fork.svg";

const styleProps = { height: '20px', width: '20px', fill: '#442D29' }
const switchProvideOption = () => {
    switch (process.env.REACT_APP_PROVIDE_OPTION) {
        case "all":
            return (
                    <>
                        <RadioControl radioValue={'delivery'} radioLabelText={'配達'}
                                      icon={<DeliveryIcon style={styleProps} />} />
                        <RadioControl radioValue={'takeout'} radioLabelText={'お持ち帰り'}
                                      icon={<TakeoutIcon style={styleProps} />} />
                        <RadioControl radioValue={'eatIn'} radioLabelText={'店内飲食'}
                                      icon={<EatInIcon style={styleProps} />} />
                    </>
            )
        case "deliveryOnly":
            return (
                    <RadioControl radioValue={'delivery'} radioLabelText={'配達'}
                                  icon={<DeliveryIcon style={styleProps} />} />

            )
        case "eatInOnly":
            return (<RadioControl
                    radioValue={'eatIn'}
                    radioLabelText={'店内飲食'}
                    icon={<TakeoutIcon style={styleProps} />}
            />)
        default :
            return (
                    <>
                        <RadioControl radioValue={'takeout'} radioLabelText={'お持ち帰り'}
                                      icon={<TakeoutIcon style={styleProps} />} />
                        <RadioControl radioValue={'eatIn'} radioLabelText={'店内飲食'}
                                      icon={<EatInIcon style={styleProps} />} />
                    </>
            )
    }
}
const DeliveryOptions = () => {
    return (
            <>
                {switchProvideOption()}
            </>
    )
}

export default DeliveryOptions